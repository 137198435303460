module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Beam","short_name":"Beam","start_url":"/","background_color":"#0A9AFF","theme_color":"#FFFFFF","display":"standalone","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7ed616c2174c0593711a3d058cf605be"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-NL3SJLMYKE"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"beam-marketing-site","accessToken":"MC5ZbXF3cnhNQUFDQUFlZGVz.77-9diBOC--_vRjvv73vv73vv71M77-977-977-977-977-977-9ce-_ve-_vXlV77-9Iu-_vV8LJAhL77-9aw","promptForAccessToken":true,"apiEndpoint":"https://beam-marketing-site.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    }]
