import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';

import { CopySection } from '../components/copySection';
import { DemoType } from '../components/demoType';
import { Footer } from '../components/footer';
import { Hero } from '../components/hero';
import { RichText } from '../components/richText';
import { Video } from '../components/video';
import { View } from '../components/view';
import { DemoQuery, PrismicDemoDataBodyCopyBlock } from '../generated/graphql';

import './demo.scss';

const DemoView: React.FC<PageProps<DemoQuery>> = ({ data }) => {
  return (
    <View
      className="demo-view"
      title={data.prismicDemo?.data.name || undefined}
      description={data.prismicDemo?.data.short_description?.text || undefined}
    >
      <Hero
        left={
          data.prismicDemo?.data?.video_url && (
            <Video
              videoUrl={data.prismicDemo.data.video_url}
              fallbackImages={
                data.prismicDemo.data.image?.gatsbyImageData && [data.prismicDemo.data.image.gatsbyImageData]
              }
            />
          )
        }
      >
        {data.prismicDemo?.data.type && <DemoType type={data.prismicDemo?.data.type} />}
        <h1>{data.prismicDemo?.data.name}</h1>
        {data.prismicDemo?.data.short_description && (
          <RichText field={data.prismicDemo.data.short_description.richText} />
        )}
      </Hero>

      <div className="demo-inner">
        {data.prismicDemo?.data.body.map((slice, index) => (
          <CopySection
            content={(slice as PrismicDemoDataBodyCopyBlock).primary.content?.richText || []}
            image={(slice as PrismicDemoDataBodyCopyBlock).primary.image?.gatsbyImageData}
            text_side={(slice as PrismicDemoDataBodyCopyBlock).primary.text_side || undefined}
            variant={(slice as PrismicDemoDataBodyCopyBlock).primary.variant || undefined}
            title={(slice as PrismicDemoDataBodyCopyBlock).primary.title || undefined}
            key={(slice as PrismicDemoDataBodyCopyBlock).primary.title || `${index}`}
            video_url={(slice as PrismicDemoDataBodyCopyBlock).primary.video_url || undefined}
          />
        ))}
      </div>

      <Footer />
    </View>
  );
};

export const pageQuery = graphql`
  query Demo($id: String!) {
    prismicDemo(id: { eq: $id }) {
      _previewable
      data {
        body {
          ... on PrismicDemoDataBodyCopyBlock {
            id
            primary {
              image {
                gatsbyImageData(width: 600, placeholder: BLURRED)
              }
              content {
                richText
              }
              video_url
              title
              variant
              text_side
            }
          }
        }
        image {
          gatsbyImageData(width: 600, placeholder: BLURRED)
        }
        video_url
        name
        short_description {
          richText
          text
        }
        type
      }
    }
  }
`;

export default withPrismicPreview(DemoView);
