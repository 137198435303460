import { ClassNames, useInViewport } from '@rocketmakers/armstrong-edge';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import { useScreenSize } from '../hooks/useScreenSize';

import { FadingImage } from './fadingImage';

import './video.scss';

interface IVideoProps {
  videoUrl: string;
  videoPosterUrl?: string;
  className?: string;
  fill?: boolean;

  /** fallback to images if present and on mobile - will fade between if multiple are provided */
  fallbackImages?: IGatsbyImageData[];
}

export const Video: React.FunctionComponent<IVideoProps> = ({
  videoUrl,
  videoPosterUrl,
  className,
  fill,
  fallbackImages,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const videoRef = React.useRef<HTMLVideoElement>(null);

  const enteredViewport = useInViewport(ref, { rootMargin: '200px' });
  const [load, setLoad] = React.useState(false);

  const [playing, setPlaying] = React.useState(false);

  const screenSize = useScreenSize();

  React.useEffect(() => {
    if (enteredViewport) {
      if (!playing) {
        videoRef.current?.play();
        setLoad(true);
      }
    } else if (playing) {
      videoRef.current?.pause();
    }
  }, [enteredViewport]);

  return (
    <div
      className={ClassNames.concat('video-wrapper', className)}
      ref={ref}
      data-in-view={enteredViewport}
      data-fill={fill}
      data-has-fallback-images={!!fallbackImages}
    >
      {(!fallbackImages || screenSize !== 'small') && (
        <video
          className="video"
          loop
          preload="none"
          muted
          poster={videoPosterUrl}
          ref={videoRef}
          aria-hidden="true"
          onPlaying={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
        >
          {load && <source src={videoUrl} type="video/mp4" />}
        </video>
      )}

      {fallbackImages && <FadingImage images={fallbackImages} />}
    </div>
  );
};
