import { EmailInput, Form, Select, TextArea, TextInput, useDispatchToast } from '@rocketmakers/armstrong-edge';
import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';

import { HubspotApi } from '../api/hubspot';
import { BmButton } from '../components/button';
import { Card } from '../components/card';
import { Footer } from '../components/footer';
import { RichText } from '../components/richText';
import { Video } from '../components/video';
import { View } from '../components/view';
import { GetInTouchPageQuery } from '../generated/graphql';

import { SvgIcons } from '../components/svgIcon';

import './get-in-touch.scss';

const GetInTouchView: React.FC<PageProps<GetInTouchPageQuery>> = ({ data }) => {
  const { formProp, formState } = Form.use<HubspotApi.IContactForm>({
    name: '',
    email: '',
    enquiryType: '',
    message: '',
  });

  const dispatch = useDispatchToast();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const submit = React.useCallback(async () => {
    try {
      if (formState?.email && formState.enquiryType && formState.name) {
        setLoading(true);
        await HubspotApi.submitContact(formState);
        setSuccess(true);
      }
    } catch (err) {
      if (HubspotApi.isHubspotError(err)) {
        setError(true);
        dispatch({ type: 'error', content: err.message });
      }
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <View
      className="get-in-touch-view"
      title={data.prismicGetInTouch?.data.title || undefined}
      description={data.prismicGetInTouch?.data.description?.text || undefined}
    >
      <section className="get-in-touch-view-form-section">
        <div className="get-in-touch-view-form-section-background">
          {data.prismicGetInTouch?.data.background_video_url && (
            <Video fill videoUrl={data.prismicGetInTouch?.data.background_video_url} />
          )}
        </div>
        <Card className="get-in-touch-view-form">
          <div className="get-in-touch-view-form-left">
            <h1>{data.prismicGetInTouch?.data.title}</h1>
            {data.prismicGetInTouch?.data.description && (
              <RichText field={data.prismicGetInTouch.data.description.richText} />
            )}

            <BmButton to={data.prismicGetInTouch?.data.get_started_button_link?.url || undefined}
                variant="secondary-transparent">
              {data.prismicGetInTouch?.data.get_started_button_text?.text}
              </BmButton>

          </div>

          <div className="get-in-touch-view-form-right">
            {success ? (
              <>
                <h2>Submitted successfully</h2>
                <p>Someone will be in touch soon</p>
              </>
            ) : (
              <form onSubmit={submit}>
                <div>
                  <label>
                    {data.prismicGetInTouch?.data.name_caption}*
                    <TextInput bind={formProp('name').bind()} />
                  </label>
                </div>
                <div>
                  <label>
                    {data.prismicGetInTouch?.data.email_caption}*
                    <EmailInput bind={formProp('email').bind()} />
                  </label>
                </div>
                <div>
                  <label>
                    {data.prismicGetInTouch?.data.enquiry_type_caption}*
                    <Select
                      placeholderOption="please select..."
                      bind={formProp('enquiryType').bind()}
                      options={
                        data.prismicGetInTouch?.data.enquiry_type_options?.map(option => ({
                          id: option?.enquiry_type_option_name || '',
                        })) || []
                      }
                      selectOverlayIcon={<SvgIcons.Down />}
                    />
                  </label>
                </div>
                <div>
                  <label>
                    {data.prismicGetInTouch?.data.message_caption}
                    <TextArea bind={formProp('message').bind()} />
                  </label>
                </div>
                <p>*required</p>

                <BmButton error={error} pending={loading}>
                  {data.prismicGetInTouch?.data.submit_button_text}
                </BmButton>
              </form>
            )}
          </div>
        </Card>
      </section>

      <Footer />
    </View>
  );
};

export const pageQuery = graphql`
  query GetInTouchPage {
    prismicGetInTouch {
      _previewable
      data {
        description {
          richText
          text
        }
        get_started_button_link {
          url
        }
        get_started_button_text {
          richText
          text
        }
        email_caption
        enquiry_type_caption
        enquiry_type_options {
          enquiry_type_option_name
        }
        message_caption
        name_caption
        title
        background_video_url
        submit_button_text
      }
    }
  }
`;

export default withPrismicPreview(GetInTouchView);
