import { PrismicRichTextProps } from '@prismicio/react';
import * as React from 'react';

import { RichText } from './richText';

import './sectionTitle.scss';

interface ISectionTitleProps {
  title: string;
  description?: PrismicRichTextProps['field'];
}

export const SectionTitle: React.FunctionComponent<ISectionTitleProps> = ({ title, description }) => {
  return (
    <div className="section-title">
      <div className="section-title-title-wrapper">
        <h3>{title}</h3>
      </div>

      <RichText field={description} />
    </div>
  );
};
