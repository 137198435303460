import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';

import { BmButton } from '../components/button';
import { Footer } from '../components/footer';
import { Hero } from '../components/hero';
import { RichText } from '../components/richText';
import { SectionTitle } from '../components/sectionTitle';
import { View } from '../components/view';
import { AboutUsPageQuery } from '../generated/graphql';

import './about.scss';

const AboutUsView: React.FC<PageProps<AboutUsPageQuery>> = ({ data }) => {
  return (
    <View
      className="about-us-view"
      title={data.prismicAboutUs?.data.title || undefined}
      description={data.prismicAboutUs?.data?.description?.text || undefined}
    >
      <Hero
        shape="round"
        backgroundVideoUrl={data.prismicAboutUs?.data?.video_url || undefined}
        backgroundVideoImageFallbacks={
          data.prismicAboutUs?.data.hero_background?.gatsbyImageData && [
            data.prismicAboutUs.data.hero_background.gatsbyImageData,
          ]
        }
      >
        <h1>{data.prismicAboutUs?.data.title}</h1>
        {data.prismicAboutUs?.data.description && <RichText field={data.prismicAboutUs.data.description.richText} />}
      </Hero>

      <section className="about-us-team-section">
        {data.prismicAboutUs?.data.team_title && <SectionTitle title={data.prismicAboutUs?.data.team_title} />}

        <div className="about-us-team-section-team-members">
          {data.prismicAboutUs?.data.team_member?.map(teamMember => (
            <div className="about-us-team-section-team-member" key={teamMember?.name}>
              <div className="about-us-team-section-team-member-image">
                {teamMember?.image && <GatsbyImage image={teamMember.image.gatsbyImageData} alt="" />}
              </div>
              {teamMember?.name && <p className="about-us-team-section-name">{teamMember.name}</p>}
              {teamMember?.role && <p>{teamMember.role}</p>}
              {teamMember?.description && <RichText field={teamMember.description.richText} />}
            </div>
          ))}
        </div>
      </section>

      <section className="about-us-rocketmakers-section">
        <div className="about-us-rocketmakers-section-background">
          {data.prismicAboutUs?.data.rocketmakers_background && (
            <GatsbyImage image={data.prismicAboutUs?.data.rocketmakers_background.gatsbyImageData} alt="" />
          )}
        </div>

        <div className="about-us-rocketmakers-section-inner">
          <div className="about-us-rocketmakers-section-left" />
          <div className="about-us-rocketmakers-section-right">
            <div className="about-us-rocketmakers-section-right-copy">
              {data.prismicAboutUs?.data.rocketmakers_logo && (
                <GatsbyImage alt="Rocketmakers" image={data.prismicAboutUs?.data.rocketmakers_logo.gatsbyImageData} />
              )}
              {data.prismicAboutUs?.data.rocketmakers_description && (
                <RichText field={data.prismicAboutUs?.data.rocketmakers_description.richText} />
              )}
              <BmButton to={data.prismicAboutUs?.data.rocketmakers_url?.url || undefined}>
                {data.prismicAboutUs?.data.rocketmakers_button_text}
              </BmButton>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </View>
  );
};

export const pageQuery = graphql`
  query AboutUsPage {
    prismicAboutUs {
      _previewable
      data {
        description {
          richText
          text
        }
        hero_background {
          gatsbyImageData(width: 1800, placeholder: BLURRED)
        }
        rocketmakers_background {
          gatsbyImageData(width: 1800, placeholder: BLURRED)
        }
        rocketmakers_button_text
        rocketmakers_description {
          richText
        }
        rocketmakers_logo {
          gatsbyImageData(width: 300, layout: CONSTRAINED, placeholder: BLURRED)
        }
        rocketmakers_url {
          url
        }
        team_member {
          description {
            richText
          }
          name
          role
          image {
            gatsbyImageData(width: 450, placeholder: BLURRED)
          }
        }
        team_title
        title
        video_url
      }
    }
  }
`;

export default withPrismicPreview(AboutUsView);
