import * as React from 'react';

import { SvgIcons } from './svgIcon';

import './learnMore.scss';

export const LearnMore: React.FunctionComponent = () => {
  return (
    <div className="learn-more">
      <p className="demo-swiper-demo-learn-more">learn more</p>
      <SvgIcons.Link />
    </div>
  );
};
