import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { NavQuery } from '../generated/graphql';
import { LinkWrapper } from './linkWrapper';

import { SvgIcons } from './svgIcon';

import './nav.scss';

export const Nav: React.FunctionComponent = () => {
  const query = useStaticQuery<NavQuery>(graphql`
    query Nav {
      prismicFooter {
        data {
          rocketmakers_text
          rocketmakers_link {
            url
          }
          body {
            ... on PrismicFooterDataBodyLinkGroup {
              id
              items {
                text
                link {
                  url
                }
              }
              primary {
                title
              }
            }
          }
          copyright
        }
      }
    }
  `);

  return (
    <div className="bm-nav-wrapper">
      <nav className="bm-nav">
        {query.prismicFooter?.data.body.map((column, index) => (
          <div className="bm-nav-column" key={column.primary.title || index}>
            {column.primary.title ? <h3>{column.primary.title}</h3> : <div className="bm-nav-no-header-spacer" />}
            <ul>
              {column.items.map(item => (
                <li key={item.text} className="bm-nav-link">
                  <LinkWrapper to={item.link?.url || undefined}>
                    {item.text} <SvgIcons.Link />
                  </LinkWrapper>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </nav>

      <div className="bm-nav-lower">
        <LinkWrapper to={query.prismicFooter?.data.rocketmakers_link?.url || undefined}>
          <p>{query.prismicFooter?.data.rocketmakers_text}</p>
        </LinkWrapper>
        <p className="bm-nav-copyright-text">{query.prismicFooter?.data.copyright}</p>
      </div>
    </div>
  );
};
