import { PrismicRichTextProps } from '@prismicio/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';

import { RichText } from './richText';
import { Video } from './video';

import './copySection.scss';
import { CopySectionAnimation } from './copySectionAnimation';

interface ICopySectionProps {
  title?: string;
  image?: IGatsbyImageData;
  content?: PrismicRichTextProps['field'];
  video_url?: string;
  variant?: string;
  text_side?: string;
  animation?: string;
}

export const CopySection: React.FunctionComponent<ICopySectionProps> = ({
  image,
  content,
  video_url,
  title,
  variant,
  text_side,
  animation
}) => {

  return (
    <section className="copy-section" data-text-side={text_side} data-variant={variant}>
      <div className="copy-section-image">
        {video_url && <Video videoUrl={video_url} />}
        {image && <GatsbyImage image={image} alt="" />}
        {animation && animation !== 'none' && <CopySectionAnimation animation={animation} />}
      </div>
      <div className="copy-section-copy">
        <h2>{title}</h2>
        <RichText field={content} />
      </div>
    </section>
  );
};
