import { GeometryUtils } from './geometry';

export namespace SVG {
  type SVGPathMoveTo = GeometryUtils.ICoordinate & {
    type: 'MOVE';
  };
  type SVGPathLineTo = GeometryUtils.ICoordinate & {
    type: 'LINE';
  };
  type SVGPathClose = {
    type: 'CLOSE';
  };
  type SVGPathQuadratic = GeometryUtils.ICoordinate & {
    type: 'QUADRATIC';
    control: GeometryUtils.ICoordinate;
  };

  export type SVGPathPoint = SVGPathMoveTo | SVGPathLineTo | SVGPathClose | SVGPathQuadratic;
  const svgPathPointKey: Record<SVGPathPoint['type'], string> = {
    CLOSE: 'Z',
    LINE: 'L',
    MOVE: 'M',
    QUADRATIC: 'Q',
  };

  const svgPathPointToString = (point: SVGPathPoint) => {
    switch (point.type) {
      case 'CLOSE':
        return svgPathPointKey[point.type];
      case 'LINE':
      case 'MOVE':
        return `${svgPathPointKey[point.type]} ${point.x},${point.y}`;
      case 'QUADRATIC':
        return `${svgPathPointKey[point.type]} ${point.control.x},${point.control.y} ${point.x},${point.y}`;
      default:
        return '';
    }
  };

  export const svgPathToString = (points: SVGPathPoint[]) => points.map(point => svgPathPointToString(point)).join(' ');
}
