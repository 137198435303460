import * as React from 'react';

import { Async } from '../utils/async';

export const useBeamingState = <T>(
  initialState?: T,
  onBeam?: () => void
): [
  T | undefined,
  (newValue: T) => void,
  { beamingIn: boolean; beamingOut: boolean; setWithoutBeam: (newValue: T) => void }
] => {
  const [beamedState, setBeamedState] = React.useState<T | undefined>(initialState);

  const [beamingOut, setBeamingOut] = React.useState(false);
  const [beamingIn, setBeamingIn] = React.useState(false);

  const change = React.useCallback(
    async (newValue?: T) => {
      if (!beamingOut && !beamingIn) {
        setBeamingOut(true);
        await Async.wait(800);
        onBeam?.();
        if (newValue) {
          setBeamedState(newValue);
        }
        setBeamingOut(false);
        setBeamingIn(true);
        await Async.wait(1500);
        setBeamingIn(false);
      }
    },
    [onBeam, beamingOut, beamingIn]
  );

  return [beamedState, change, { beamingIn, beamingOut, setWithoutBeam: setBeamedState }];
};
