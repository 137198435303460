import * as React from 'react';

import { FeatureGraphicIsometric } from './featureGraphicIsometric';

// lazy load any three js
const FeatureGraphicAnalytics = React.lazy(() => import('./featureGraphicAnalytics'));
const FeatureGraphicCar = React.lazy(() => import('./featureGraphicCar'));

export interface IFeatureGraphicProps {
  type: 'isometric' | 'heatmap' | 'car';
}

export const FeatureGraphic: React.FunctionComponent<IFeatureGraphicProps> = ({ type }) => {
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  switch (type) {
    case 'isometric':
      return <FeatureGraphicIsometric />;
    case 'car':
      return (
        <>
          {!isMounted ? null : (
            <React.Suspense fallback={null}>
              <FeatureGraphicCar />
            </React.Suspense>
          )}
        </>
      );
    case 'heatmap':
    default:
      return (
        <>
          {!isMounted ? null : (
            <React.Suspense fallback={null}>
              <FeatureGraphicAnalytics />
            </React.Suspense>
          )}
        </>
      );
  }
};
