import { ClassNames, Image, useDidUpdateEffect, useTemporaryState, useWillUnMountEffect } from '@rocketmakers/armstrong-edge';
import * as React from 'react';

import { Assets } from '../content/assets';
import { MathsUtils } from '../utils/maths';

import './featureGraphicAnalytics.scss';

interface IFeatureGraphicAnalyticsBubbleProps {
  overrideSize?: number;
}

export const FeatureGraphicAnalyticsBubble: React.FC<IFeatureGraphicAnalyticsBubbleProps> = ({ overrideSize }) => {
  const left = React.useMemo(() => MathsUtils.randomBetween(20, 75, 0.1), []);
  const top = React.useMemo(() => MathsUtils.randomBetween(30, 70, 0.1), []);
  const size = React.useMemo(() => overrideSize || MathsUtils.randomBetween(1.2, 0.2, 0.05), [overrideSize]);
  const delay = React.useMemo(() => MathsUtils.randomBetween(200, 1500), []);

  const [previousSize, setPreviousSize] = React.useState(0);
  const [sizeDidUpdate, setSizeDidUpdate] = React.useState(false);

  useDidUpdateEffect(() => {
    setSizeDidUpdate(true);
  }, [overrideSize]);


  return (
    <div
      className={ClassNames.concat("feature-graphic-analytics-bubble", sizeDidUpdate ? "bubble-grow" : "bubble-start")}
      onAnimationEnd={() => setPreviousSize(size)}
      onAnimationIteration={(() => setPreviousSize(size))}
      style={
        {
          '--left': `${left}%`,
          '--top': `${top}%`,
          '--size': size,
          '--prevSize': previousSize,
          '--delay': `${delay}ms`,
        } as React.CSSProperties
      }
    />
  );
};

export const useFeatureGraphicAnalyticsAnimation = () => {
  const [changing, setChanging] = useTemporaryState(false, 300);

  const timeout = React.useRef<NodeJS.Timeout>();
  useWillUnMountEffect(() => timeout.current && window.clearTimeout(timeout.current));
  React.useEffect(() => {
    timeout.current = setTimeout(() => {
      setChanging(true);
    }, MathsUtils.randomBetween(4000, 6000));

    return () => {
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }
    };
  }, [changing]);

  return { changing, setChanging };
};

export const FeatureGraphicAnalyticsImage: React.FC = () => {
  const { changing, setChanging } = useFeatureGraphicAnalyticsAnimation();

  return (
    <div className="feature-graphic-analytics" onClick={() => setChanging(true)} aria-hidden tabIndex={-1}>
      <Image
        wrapperAttributes={{ className: 'feature-graphic-analytics-image' }}
        src={Assets.analyticsImage}
        alt=""
        additionalSources={[{ srcSet: Assets.analyticsImageSmall }, { srcSet: Assets.analyticsImageSmallWebp }]}
      />
      
      {!changing && (
        <>
          <FeatureGraphicAnalyticsBubble overrideSize={2} />
          <FeatureGraphicAnalyticsBubble />
          <FeatureGraphicAnalyticsBubble />
          <FeatureGraphicAnalyticsBubble />
          <FeatureGraphicAnalyticsBubble />
          <FeatureGraphicAnalyticsBubble />
        </>
      )}
    </div>
  );
};
