import { Arrays, useInViewport } from '@rocketmakers/armstrong-edge';
import * as React from 'react';

import { Assets } from '../content/assets';
import { useBeamingState } from '../hooks/useBeamingState';
import { MathsUtils } from '../utils/maths';
import { Beam } from './beam';

import './featureGraphicIsometric.scss';

type FeatureGraphicIsometricGridTileVariant =
  | 'square'
  | 'longX'
  | 'longY'
  | 'empty'
  | 'FILLED_BY_LONG_X'
  | 'FILLED_BY_LONG_Y';

interface IFeatureGraphicIsometricTileProps {
  variant: FeatureGraphicIsometricGridTileVariant;
  x: number;
  y: number;
  zIndex: number;

  /** time in ms from mount to animate in */
  appearOffset: number;

  populated: boolean;
}

// check if the given tile is filled by an adjacent long tile
const tileIsFilledByOther = (tile?: IFeatureGraphicIsometricTileProps) =>
  !tile || tile.variant === 'FILLED_BY_LONG_X' || tile.variant === 'FILLED_BY_LONG_Y';

// React component for an individual tile in the isometric grid
export const FeatureGraphicIsometricTile: React.FC<IFeatureGraphicIsometricTileProps> = ({
  variant,
  x,
  y,
  zIndex,
  appearOffset,
  populated,
}) => {
  /** asset for tile based on variant */
  const tileAsset = React.useMemo(() => {
    switch (variant) {
      case 'longX':
        return Assets.isometricGridTiles.longX;
      case 'longY':
        return Assets.isometricGridTiles.longY;
      case 'square':
        return Assets.isometricGridTiles.square;
      default:
        return null;
    }
  }, []);

  const [content, setContent, { beamingIn, beamingOut, setWithoutBeam }] = useBeamingState<string>();

  const loopTimeout = React.useRef<NodeJS.Timeout>();

  /** choose from a available assets */
  const getRandomContent = React.useCallback(() => {
    switch (variant) {
      case 'longX': {
        return MathsUtils.randomFromArray(
          [
            Assets.isometricGridContent.longX.car,
            Assets.isometricGridContent.longX.cart,
            Assets.isometricGridContent.longX.jeep,
          ].filter(path => path !== content)
        );
      }
      case 'longY': {
        return MathsUtils.randomFromArray(
          [
            Assets.isometricGridContent.longY.car,
            Assets.isometricGridContent.longY.cart,
            Assets.isometricGridContent.longY.jeep,
          ].filter(path => path !== content)
        );
      }
      case 'square':
      default: {
        return MathsUtils.randomFromArray(
          [
            Assets.isometricGridContent.square.armour,
            Assets.isometricGridContent.square.sword,
            Assets.isometricGridContent.square.vase,
          ].filter(path => path !== content)
        );
      }
    }
  }, [content, populated, variant, setContent]);

  /** set new content from random and initiate loop */
  const setRandomContent = React.useCallback(() => {
    if (loopTimeout.current) {
      clearTimeout(loopTimeout.current);
    }
    const newContent = getRandomContent();
    if (newContent) {
      setContent(newContent);
      setTimeout(setRandomContent, MathsUtils.randomBetween(5000, 10000));
    }
  }, [getRandomContent]);

  /** set initial content and initiate loop */
  React.useEffect(() => {
    if (populated) {
      setWithoutBeam(getRandomContent() || '');

      setTimeout(setRandomContent, MathsUtils.randomBetween(2000, 10000));
    }
  }, []);

  /** set up timeout loop for  */
  React.useEffect(() => {
    return () => {
      if (loopTimeout.current) {
        clearTimeout(loopTimeout.current);
      }
    };
  }, []);

  /** change asset when user clicks on one */
  const onClick = React.useCallback(() => {
    if (populated) {
      if (loopTimeout.current) {
        clearTimeout(loopTimeout.current);
      }
      setContent(getRandomContent() || '');
    }
  }, [getRandomContent, setContent, populated]);

  const ref = React.useRef<HTMLDivElement>(null);
  const inViewport = useInViewport(ref, { rootMargin: '100px', once: true });

  return (
    <div
      ref={ref}
      className="feature-graphic-isometric-grid-tile"
      style={
        {
          '--x': x,
          '--y': y,
          '--z-index': zIndex,
          '--appear-offset': `${appearOffset}ms`,
        } as React.CSSProperties
      }
      data-variant={variant}
      data-beaming-in={beamingIn}
      data-beaming-out={beamingOut}
      data-populated={populated}
      onClick={onClick}
    >
      {tileAsset && inViewport && <img className="feature-graphic-isometric-grid-tile-tile" src={tileAsset} alt="" />}
      {content && inViewport && <img className="feature-graphic-isometric-grid-tile-content" src={content} alt="" />}

      <Beam beamingIn={beamingIn} beamingOut={beamingOut} />
    </div>
  );
};

type FeatureGraphicIsometricGrid = Array<Array<IFeatureGraphicIsometricTileProps>>;

// find a given tile at a coordinate - if it's taken by an adjacent long tile, return that tile
const getTileAtCoordinate = (grid: FeatureGraphicIsometricGrid, x: number, y: number) => {
  const tile = grid[y]?.[x];
  if (!tile) {
    return undefined;
  }
  if (tile.variant === 'FILLED_BY_LONG_X') {
    return grid[y][x - 1];
  }
  if (tile.variant === 'FILLED_BY_LONG_Y') {
    return grid[y - 1][x];
  }
  return tile;
};

interface IFeatureGraphicIsometricProps {
  gridSize?: {
    x: number;
    y: number;
  };
  populatedTiles?: number;
  squareOnly?: boolean;
  cutCorners?: boolean;
}

/** get a random tile variant from a series of parameters */
const getTileVariant = ({
  squareOnly,
  isInUnallowedSpace,
  canBeLongX,
  canBeLongY,
}: {
  squareOnly?: boolean;
  isInUnallowedSpace?: boolean;
  canBeLongX?: boolean;
  canBeLongY?: boolean;
}): FeatureGraphicIsometricGridTileVariant => {
  if (squareOnly) {
    return 'square';
  }
  if (isInUnallowedSpace) {
    return 'empty';
  }
  return MathsUtils.randomFromArray<FeatureGraphicIsometricGridTileVariant>([
    'empty',
    'empty',
    'empty',
    ...(canBeLongX ? (['longX'] as const) : []),
    ...(canBeLongY ? (['longY'] as const) : []),
    'square',
    'square',
  ]);
};

export const FeatureGraphicIsometric: React.FC<IFeatureGraphicIsometricProps> = ({
  gridSize,
  populatedTiles,
  squareOnly,
  cutCorners,
}) => {
  const [grid, setGrid] = React.useState<FeatureGraphicIsometricGrid>([]);

  React.useEffect(() => {
    // init grid
    const newGrid: FeatureGraphicIsometricGrid = Arrays.repeat(gridSize?.y || 1, y =>
      Arrays.repeat<IFeatureGraphicIsometricTileProps>(gridSize?.x || 1, x => ({
        variant: 'empty',
        x,
        y,
        zIndex: 1,
        appearOffset: 0,
        populated: false,
      }))
    );

    // fill grid with random tiles
    for (let y = 0; y < (gridSize?.y || 1); y += 1) {
      for (let x = 0; x < (gridSize?.x || 1); x += 1) {
        if (!tileIsFilledByOther(newGrid[y][x])) {
          const canBeLongX = x < (gridSize?.x || 1) - 1 && !tileIsFilledByOther(newGrid[y][x + 1]);
          const canBeLongY = y < (gridSize?.y || 1) - 1 && !tileIsFilledByOther(newGrid[y + 1][x]);

          // dont allow tiles to be placed in corners, makes more interesting shapes
          const isInUnallowedSpace =
            cutCorners && (y < x - ((gridSize?.y || 1) - 5) || y > x + ((gridSize?.x || 1) - 3));

          // pick variant at random - prefer empty by repeating in input array
          const variant = getTileVariant({
            canBeLongX,
            canBeLongY,
            isInUnallowedSpace,
            squareOnly,
          });

          newGrid[y][x].variant = variant;

          // if longX or longY, ensure approrpiate tile is marked as empty
          switch (variant) {
            case 'longX': {
              if (newGrid[y][x + 1]) {
                newGrid[y][x + 1].variant = 'FILLED_BY_LONG_X';
              }
              break;
            }
            case 'longY': {
              if (newGrid[y + 1]) {
                newGrid[y + 1][x].variant = 'FILLED_BY_LONG_Y';
              }
              break;
            }
            default:
              break;
          }

          // set each tile to appear a random interval from mount up to 1 second
          newGrid[y][x].appearOffset = MathsUtils.randomBetween(0, 500);
        }
      }
    }

    // sort z index of tiles by marching from the bottom left in diagonal lines (rescursively) and ensuring it's below tiles on row below

    // i.e. in this order
    // 9  7  4
    // 8  5  2
    // 6  3  1

    const getTileZIndex = (x: number, y: number, currentZIndex: number) => {
      let zIndex = currentZIndex - 1;

      const current = newGrid[y][x];

      if (!tileIsFilledByOther(current)) {
        const below = getTileAtCoordinate(newGrid, x, y + 1);
        const right = getTileAtCoordinate(newGrid, x + 1, y);

        // hack because I spent way too long figuring out how to get the longX tiles not to appear below ones to the upper right of them in the z stack
        // and needed to move on, and this looks fine anyway so 🤷‍♂️
        const rightAbove = getTileAtCoordinate(newGrid, x + 1, y - 1);
        if (current.variant === 'longX' && rightAbove) {
          rightAbove.variant = 'empty';
        }

        zIndex = Math.min((below?.zIndex || 0) - 1, (right?.zIndex || 0) - 1, zIndex);

        current.zIndex = zIndex;
      }

      // pick another tile to run this on based on presence of adjacents

      // if another tile to the bottom left of this one, continue diagonally
      if (newGrid[y + 1]?.[x - 1]) {
        getTileZIndex(x - 1, y + 1, zIndex);
        // if another tile is above it, discern that there is another diagonal line above to start
      } else if (newGrid[y - 1]?.[x]) {
        // move diagonally up right to edge to find start of next diagonal line
        let newX = x;
        let newY = y - 1;

        while (newY > 0 && newX < (gridSize?.x || 1) - 1) {
          newX += 1;
          newY -= 1;
        }

        getTileZIndex(newX, newY, currentZIndex);
      }
    };

    getTileZIndex((gridSize?.x || 1) - 1, (gridSize?.y || 1) - 1, 1000);

    // pick a specific number of random tiles to have graphics on them
    let hasPopulated = 0;
    let maxPasses = 0;

    while (hasPopulated <= (populatedTiles || 1) && maxPasses < 100) {
      const randomX = MathsUtils.randomBetween(0, gridSize?.x || 1);
      const randomY = MathsUtils.randomBetween(0, gridSize?.y || 1);

      const tile = getTileAtCoordinate(newGrid, randomX, randomY);
      if (tile && !tile.populated && tile.variant !== 'empty') {
        hasPopulated += 1;
        tile.populated = true;
      }
      maxPasses += 1;
    }

    setGrid(newGrid);
  }, []);

  return (
    <div
      className="feature-graphic-isometric"
      style={{ '--x-max': gridSize?.x || 1, '--y-max': gridSize?.y || 1 } as React.CSSProperties}
      aria-hidden
      tabIndex={-1}
    >
      <div className="feature-graphic-isometric-inner">
        {grid.map((row, y) => (
          <div key={y} className="feature-graphic-isometric-row">
            {row.map((tile, x) => (
              <FeatureGraphicIsometricTile
                {...tile}
                key={x + tile.variant}
                x={x}
                y={y}
                zIndex={tile.zIndex}
                appearOffset={tile.appearOffset}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

FeatureGraphicIsometric.defaultProps = {
  gridSize: {
    x: 9,
    y: 9,
  },
  populatedTiles: 8,
  cutCorners: true,
};

export default FeatureGraphicIsometric;
