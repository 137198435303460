import analyticsImageSmallWebp from '../assets/analytics_image_small.webp';
import analyticsScene from '../assets/analytics_scene.glb';
import gunnerSmallWebp from '../assets/carImages/gunner_small.webp';
import muscleSmallWebp from '../assets/carImages/muscle_small.webp';
import sedanSmallWebp from '../assets/carImages/sedan_small.webp';
import sportsSmallWebp from '../assets/carImages/sports_small.webp';
// car models
import car1 from '../assets/carModels/car1.glb';
import car2 from '../assets/carModels/car2.glb';
import car3 from '../assets/carModels/car3.glb';
import car4 from '../assets/carModels/car4.glb';
import car5 from '../assets/carModels/car5.glb';
import car6 from '../assets/carModels/car6.glb';
import car7 from '../assets/carModels/car7.glb';
import car8 from '../assets/carModels/car8.glb';
import car11 from '../assets/carModels/car11.glb';
import car13 from '../assets/carModels/car13.glb';

import analyticsImage from '../assets/analytics_image.png';
import analyticsImageSmall from '../assets/analytics_image_small.png';
import car from '../assets/car.png';
// car images
import gunner from '../assets/carImages/gunner.png';
import gunnerSmall from '../assets/carImages/gunner_small.png';
import muscle from '../assets/carImages/muscle.png';
import muscleSmall from '../assets/carImages/muscle_small.png';
import sedan from '../assets/carImages/sedan.png';
import sedanSmall from '../assets/carImages/sedan_small.png';
import sports from '../assets/carImages/sports.png';
import sportsSmall from '../assets/carImages/sports_small.png';
import heatmap from '../assets/heatmap.png';
import isometric from '../assets/isometric.png';
import carPaletteAlbedo from '../assets/palette_albedo.png';
import carPaletteMetalness from '../assets/palette_metalness.png';
import carBase from '../assets/car_base.svg';
// isometric grid tiles
import isometricGridLongX from '../assets/isometric_grid_long_x.svg';
import isometricGridLongY from '../assets/isometric_grid_long_y.svg';
import isometricGridSquare from '../assets/isometric_grid_square.svg';
// isometric grid assets
import longXCar from '../assets/long_x_car.svg';
import longXCart from '../assets/long_x_cart.svg';
import longXJeep from '../assets/long_x_jeep.svg';
import longYCar from '../assets/long_y_car.svg';
import longYCart from '../assets/long_y_cart.svg';
import longYJeep from '../assets/long_y_jeep.svg';
import squareArmour from '../assets/square_armour.svg';
import squareSword from '../assets/square_sword.svg';
import squareVase from '../assets/square_vase.svg';

export const Assets = {
  car,
  heatmap,
  isometric,
  isometricGridTiles: {
    longX: isometricGridLongX,
    longY: isometricGridLongY,
    square: isometricGridSquare,
  },
  isometricGridContent: {
    longX: {
      car: longXCar,
      cart: longXCart,
      jeep: longXJeep,
    },
    longY: {
      car: longYCar,
      cart: longYCart,
      jeep: longYJeep,
    },
    square: {
      armour: squareArmour,
      sword: squareSword,
      vase: squareVase,
    },
  },
  carModels: {
    car1,
    car2,
    car3,
    car4,
    car5,
    car6,
    car7,
    car8,
    car11,
    car13,
  },
  carImages: {
    gunner,
    muscle,
    sedan,
    sports,
  },
  carImagesSmall: {
    gunner: gunnerSmall,
    muscle: muscleSmall,
    sedan: sedanSmall,
    sports: sportsSmall,
  },
  carImagesSmallWebp: {
    gunner: gunnerSmallWebp,
    muscle: muscleSmallWebp,
    sedan: sedanSmallWebp,
    sports: sportsSmallWebp,
  },
  carBase,
  carPalette: {
    albedo: carPaletteAlbedo,
    metalness: carPaletteMetalness,
  },
  analyticsScene,
  analyticsImage,
  analyticsImageSmall,
  analyticsImageSmallWebp,
};
