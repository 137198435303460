import { ClassNames } from '@rocketmakers/armstrong-edge';
import * as React from 'react';

import { IMetaProps, Meta } from './meta';

import './view.scss';

export interface IViewProps
  extends React.PropsWithChildren<React.HTMLProps<HTMLDivElement>>,
    Pick<IMetaProps, 'title' | 'description' | 'imageUrl'> {}

export const View: React.FC<IViewProps> = ({ children, className, title, description, imageUrl, ...props }) => {
  return (
    <>
      <Meta title={title} description={description} imageUrl={imageUrl} />

      <main className={ClassNames.concat('view', className)} {...props}>
        {children}
      </main>
    </>
  );
};
