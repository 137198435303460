import * as React from 'react';

import './beam.scss';

interface IBeamProps {
  beamingIn?: boolean;
  beamingOut?: boolean;
}

export const Beam: React.FunctionComponent<IBeamProps> = ({ beamingIn, beamingOut }) => {
  if (!beamingIn && !beamingOut) {
    return null;
  }

  return <div data-beaming-in={beamingIn} data-beaming-out={beamingOut} className="beam" />;
};
