import * as React from 'react';

import { Nav } from './nav';

import './footer.scss';

export const Footer: React.FunctionComponent = () => {
  return (
    <footer className="bm-footer">
      <Nav />
    </footer>
  );
};
