import 'swiper/css';

import * as React from 'react';

import { Header, IHeaderProps } from './header';
import { RootMeta } from './meta';

import './shell.scss';

export interface IShellProps {
  children: React.ReactNode;
  headerVariant: IHeaderProps['variant'];
}

const Shell: React.FunctionComponent<IShellProps> = ({ children, headerVariant }) => {
  return (
    <>
      <RootMeta />
      <Header variant={headerVariant} />
      {children}
    </>
  );
};

export default Shell;
