import { AxiosError } from 'axios';

import { getFetcher } from './fetcher';

export namespace HubspotApi {
  const baseURL = 'https://api.hsforms.com';
  const fetcher = getFetcher(baseURL);

  interface FormField {
    name: string;
    value: string;
  }

  const forms = {
    contact: {
      region: 'na1',
      portalId: '20197575',
      formId: '4f310c20-78a3-4c9f-9e83-756cf71364b2',
    },
  };

  export const submitForm = (portalId: string, formId: string, fields: FormField[]) =>
    fetcher.request(`/submissions/v3/integration/submit/${portalId}/${formId}`, 'POST', 'application/json', { fields });

  export interface IContactForm {
    name: string;
    email: string;
    enquiryType: string;
    message: string;
  }
  export const submitContact = (form: IContactForm) => {
    submitForm(forms.contact.portalId, forms.contact.formId, [
      {
        name: 'firstName',
        value: form.name,
      },
      {
        name: 'email',
        value: form.email,
      },
      {
        name: 'enquiryType',
        value: form.enquiryType,
      },
      {
        name: 'message',
        value: form.message,
      },
    ]);
  };

  export type HubspotError = AxiosError<{ errors: Array<{ message: string }> }>;
  export const isHubspotError = (error: unknown): error is HubspotError =>
    !!(error as HubspotError).response?.data?.errors;
}
