import { Button, ClassNames, IButtonProps, LinkButton } from '@rocketmakers/armstrong-edge';
import * as React from 'react';

import { SvgIcons } from './svgIcon';

import './button.scss';

export interface IBmButtonProps extends Omit<IButtonProps, 'to'> {
  variant?: 'gradient' | 'secondary' | 'secondary-transparent'; // add more variants as needed to string union
  to?: string;
}

export const BmButton: React.FC<IBmButtonProps> = ({ variant, className, to, ...props }) => {
  return to ? (
    <LinkButton to={to} className={ClassNames.concat('bm-button', className)} {...props} data-variant={variant} />
  ) : (
    <Button className={ClassNames.concat('bm-button', className)} {...props} data-variant={variant} />
  );
};

BmButton.defaultProps = {
  variant: 'gradient',
  rightIcon: <SvgIcons.Link />,
};
