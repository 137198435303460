export namespace MathsUtils {
  export const randomFromArray = <T>(array: T[]) => {
    return array[Math.floor(Math.random() * array.length)];
  };

  export const randomOfPercent = (percent: number) => {
    return Math.random() * 100 <= percent;
  };

  export const randomBetweenRanges = (ranges: [number, number][], step = 1) => {
    let randomValues: number[] = []
    // Generate random value for each range
    ranges.forEach(range => {
      randomValues = [...randomValues, randomBetween(range[0], range[1], step)]
    });

    // Return random value from new array
    return randomFromArray(randomValues);
  }

  export const randomBetween = (min: number, max: number, step = 1) => {
    return roundToNearest(Math.random() * (max - min) + min, step);
  };

  export const roundToNearest = (input: number, step = 1) => {
    return Math.round(input / step) * step;
  };
}
