import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';

import { BmButton } from '../components/button';
import { Faqs } from '../components/faqs';
import { Footer } from '../components/footer';
import { Hero } from '../components/hero';
import { Platforms } from '../components/platforms';
import { RichText } from '../components/richText';
import { SectionTitle } from '../components/sectionTitle';
import { View } from '../components/view';
import { GetStartedPageQuery, PrismicPlatform } from '../generated/graphql';
import { Routes } from '../utils/routes';

import './get-started.scss';

const GetStartedView: React.FC<PageProps<GetStartedPageQuery>> = ({ data }) => {
  return (
    <View
      className="get-started-view"
      title={data.prismicGetStarted?.data.title || undefined}
      description={data.prismicGetStarted?.data.description?.text || undefined}
    >
      <Hero
        darkenBackground={false}
        shape="square"
        left={
          <div className="get-started-view-hero-copy">
            <h1>{data.prismicGetStarted?.data.title}</h1>
            {data.prismicGetStarted?.data.description?.richText && (
              <RichText field={data.prismicGetStarted.data.description.richText} />
            )}
            <Platforms
              platforms={data.prismicGetStarted?.data.platforms?.map(platform => ({
                image: (platform?.platform?.document as PrismicPlatform)?.data?.logo?.gatsbyImageData || undefined,
                url: (platform?.platform?.document as PrismicPlatform)?.data?.url?.url || undefined,
                name: (platform?.platform?.document as PrismicPlatform)?.data?.name || undefined,
              }))}
            />
            <BmButton to={Routes.getInTouch}>{data.prismicGetStarted?.data.get_in_touch_button_text}</BmButton>
          </div>
        }
      >
        <GatsbyImage image={data.prismicGetStarted?.data.hero_image?.gatsbyImageData} alt="" />
      </Hero>

      <section className="get-started-faqs-section">
        <div className="get-started-faqs-section-inner">
          <SectionTitle title={data.prismicGetStarted?.data.faqs_title || ''} />
          <Faqs
            faqs={
              data.allPrismicFaqs.edges.map(faq => ({
                question: faq.node.data.question || undefined,
                answer: faq.node.data.answer?.richText || undefined,
              })) || []
            }
          />
        </div>
      </section>

      <Footer />
    </View>
  );
};

export const pageQuery = graphql`
  query GetStartedPage {
    prismicGetStarted {
      _previewable
      data {
        description {
          richText
          text
        }
        title
        faqs_title
        get_in_touch_button_text
        hero_image {
          gatsbyImageData(width: 800)
        }
        platforms {
          platform {
            uid
            document {
              ... on PrismicPlatform {
                id
                data {
                  name
                  logo {
                    gatsbyImageData(width: 150, layout: CONSTRAINED)
                  }
                  url {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicFaqs {
      edges {
        node {
          data {
            answer {
              richText
            }
            question
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(GetStartedView);
