import { Button } from '@rocketmakers/armstrong-edge';
import * as React from 'react';

import './hamburgerButton.scss';

export interface IHamburgerButtonProps {
  isOpen?: boolean;
  onClick?: () => void;
}

export const HamburgerButton: React.FC<IHamburgerButtonProps> = ({ onClick, isOpen }) => {
  return (
    <Button
      minimalStyle
      className="bm-hamburger-button"
      data-is-open={isOpen}
      onClick={onClick}
      aria-expanded={isOpen}
      aria-controls="hamburger-menu"
      name={isOpen ? 'Close Menu' : 'Open Menu'}
      aria-label={isOpen ? 'Close Menu' : 'Open Menu'}
    >
      <div className="bm-hamburger-button-inner"></div>
    </Button>
  );
};
