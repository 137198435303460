import { ClassNames } from '@rocketmakers/armstrong-edge';
import * as React from 'react';

import { LinkWrapper } from './linkWrapper';

import './card.scss';

interface ICardProps extends React.PropsWithChildren<React.HTMLProps<HTMLDivElement>> {
  className?: string;
  to?: string;
  anchorProps?: Omit<React.HTMLProps<HTMLAnchorElement>, 'ref'>;
}

export const Card = React.forwardRef<HTMLDivElement, ICardProps>(
  ({ className, children, to, anchorProps, ...props }, ref) => {
    return (
      <LinkWrapper to={to} {...anchorProps}>
        <div {...props} ref={ref} className={ClassNames.concat('card', className)}>
          {children}
        </div>
      </LinkWrapper>
    );
  }
);

Card.displayName = 'Card';
