import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';

import { BmButton } from '../components/button';
import { DemosSwiper } from '../components/demosSwiper';
import { FeatureCard } from '../components/featureCard';
import { Footer } from '../components/footer';
import { Hero } from '../components/hero';
import { IndustriesSwiper } from '../components/industriesSwiper';
import { Platforms } from '../components/platforms';
import { RichText } from '../components/richText';
import { SectionTitle } from '../components/sectionTitle';
import { Video } from '../components/video';
import { View } from '../components/view';
import { IndexPageQuery, PrismicDemo, PrismicFeature, PrismicIndustry, PrismicPlatform } from '../generated/graphql';
import { Routes } from '../utils/routes';

import './index.scss';

const IndexView: React.FC<PageProps<IndexPageQuery>> = ({ data }) => {
  return (
    <View className="home-view">
      <Hero
        backgroundVideoUrl={data.prismicHomePage?.data.hero_vimeo_url || undefined}
        shape="round"
        backgroundVideoImageFallbacks={
          data.prismicHomePage?.data.hero_image?.gatsbyImageData &&
          data.prismicHomePage?.data.hero_image_alt?.gatsbyImageData && [
            data.prismicHomePage.data.hero_image.gatsbyImageData,
            data.prismicHomePage.data.hero_image_alt.gatsbyImageData,
          ]
        }
      >
        <RichText field={data.prismicHomePage?.data.hero_copy?.richText} />

        <Platforms
          platforms={data.prismicHomePage?.data.platforms?.map(platform => ({
            image: (platform?.platform?.document as PrismicPlatform)?.data?.logo_white?.gatsbyImageData || undefined,
            url: (platform?.platform?.document as PrismicPlatform)?.data?.url?.url || undefined,
            name: (platform?.platform?.document as PrismicPlatform)?.data?.name || undefined,
          }))}
        />

        <div className="button-section">
          <BmButton to={data.prismicHomePage?.data?.hero_button_link?.url || undefined}>
            {data.prismicHomePage?.data.hero_button_text}
          </BmButton>

          <BmButton to={data.prismicHomePage?.data?.hero_button_secondary_link?.url || undefined} variant='secondary'>
            {data.prismicHomePage?.data.hero_button_secondary_text}
          </BmButton>
        </div>

      </Hero>

      <section className="features-section">
        <div className="features-section-inner">
          {data?.prismicHomePage?.data?.features_title && (
            <SectionTitle title={data.prismicHomePage.data.features_title} />
          )}

          <div className="features-section-features">
            {data?.prismicHomePage?.data?.features?.map(feature => (
              <FeatureCard
                to={(feature?.feature?.uid && Routes.feature(feature?.feature?.uid)) || undefined}
                key={(feature?.feature?.document as PrismicFeature).data.name}
                name={(feature?.feature?.document as PrismicFeature).data?.name || undefined}
                subtitle={(feature?.feature?.document as PrismicFeature).data?.subtitle || undefined}
                graphicVariant={(feature?.feature?.document as PrismicFeature).data.graphic || undefined}
              />
            ))}
          </div>
        </div>
      </section>

      {/* <section className="industries-section">
        <IndustriesSwiper
          title={data.prismicHomePage?.data.industries_title || undefined}
          description={data.prismicHomePage?.data.industries_content?.richText}
          industries={
            data.prismicHomePage?.data.industries?.map(industry => ({
              coverImage: (industry?.industry?.document as PrismicIndustry).data.hero_image?.gatsbyImageData,
              name: (industry?.industry?.document as PrismicIndustry).data.name || undefined,
              icon: (industry?.industry?.document as PrismicIndustry).data.icon?.gatsbyImageData || undefined,
              description: (industry?.industry?.document as PrismicIndustry).data.hero_text?.richText || undefined,
              to: Routes.industry((industry?.industry?.document as PrismicIndustry).uid) || undefined,
              videoUrl: (industry?.industry?.document as PrismicIndustry).data.vimeo_url || undefined,
              uid: industry?.industry?.uid || undefined,
            })) || []
          }
        />
      </section> */}

      {/* <section className="demos-section">
        <DemosSwiper
          demos={
            data.prismicHomePage?.data.demos?.map(demo => ({
              name: (demo?.demo?.document as PrismicDemo).data.name || '',
              description: (demo?.demo?.document as PrismicDemo).data.short_description?.richText || [],
              type: (demo?.demo?.document as PrismicDemo).data.type || '',
              uid: demo?.demo?.uid || '',
              videoUrl: (demo?.demo?.document as PrismicDemo).data?.video_url || '',
              image: (demo?.demo?.document as PrismicDemo).data?.image?.gatsbyImageData || undefined,
            })) || []
          }
        />
      </section> */}

      <section className="about-us-section">
        <div className="about-us-section-background">
          {data.prismicHomePage?.data.about_us_vimeo_link && (
            <Video
              fill
              videoUrl={data.prismicHomePage.data.about_us_vimeo_link}
              fallbackImages={
                data?.prismicHomePage.data.about_us_image?.gatsbyImageData &&
                data.prismicHomePage.data.about_us_image_alt?.gatsbyImageData && [
                  data.prismicHomePage.data.about_us_image.gatsbyImageData,
                  data.prismicHomePage.data.about_us_image_alt.gatsbyImageData,
                ]
              }
            />
          )}
        </div>

        <div className="about-us-section-inner">
          <div className="about-us-section-left" />
          <div className="about-us-section-right">
            <div className="about-us-section-right-copy">
              {data.prismicHomePage?.data.about_us_title && <h2>{data.prismicHomePage.data.about_us_title}</h2>}
              {data.prismicHomePage?.data.about_us_copy && (
                <RichText field={data.prismicHomePage?.data.about_us_copy.richText} />
              )}
              <BmButton to={Routes.aboutUs}>{data.prismicHomePage?.data.about_us_button_text}</BmButton>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </View>
  );
};

export const pageQuery = graphql`
  query IndexPage {
    prismicHomePage {
      _previewable
      data {
        about_us_button_text
        about_us_copy {
          richText
        }
        about_us_vimeo_link
        about_us_title
        about_us_image {
          gatsbyImageData(width: 600, layout: CONSTRAINED, placeholder: BLURRED)
        }
        about_us_image_alt {
          gatsbyImageData(width: 600, layout: CONSTRAINED, placeholder: BLURRED)
        }
        demos {
          demo {
            document {
              ... on PrismicDemo {
                id
                data {
                  video_url
                  name
                  short_description {
                    richText
                  }
                  image {
                    gatsbyImageData(width: 300, height: 300, layout: CONSTRAINED, placeholder: BLURRED)
                  }
                  type
                }
              }
            }
            uid
          }
        }
        features {
          feature {
            uid
            document {
              ... on PrismicFeature {
                id
                data {
                  name
                  graphic
                  subtitle
                }
              }
            }
          }
        }
        features_title
        hero_button_link {
          url
        }
        hero_button_text
        hero_button_secondary_link {
          url
        }
        hero_button_secondary_text
        hero_vimeo_url
        hero_copy {
          richText
        }
        hero_image {
          gatsbyImageData(width: 600, layout: CONSTRAINED, placeholder: BLURRED)
        }
        hero_image_alt {
          gatsbyImageData(width: 600, layout: CONSTRAINED, placeholder: BLURRED)
        }
        industries {
          industry {
            uid
            document {
              ... on PrismicIndustry {
                id
                data {
                  name
                  vimeo_url
                  hero_text {
                    text
                  }
                  hero_image {
                    gatsbyImageData(width: 1024)
                  }
                  icon {
                    gatsbyImageData(width: 100)
                  }
                }
              }
            }
          }
        }
        industries_content {
          richText
        }
        industries_title
        platforms {
          platform {
            uid
            document {
              ... on PrismicPlatform {
                id
                data {
                  name
                  url {
                    url
                  }
                  logo_white {
                    gatsbyImageData(width: 160, layout: CONSTRAINED)
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(IndexView);
