import axios, { AxiosRequestConfig, Method } from 'axios';

export const getFetcher = (baseURL: string) => ({
  async request<T>(url: string, method: Method, contentType: string, body?: {}): Promise<T> {
    const headers = {
      'Content-Type': contentType,
    };

    const config: AxiosRequestConfig = { baseURL, method, url, headers, data: body };

    const response = await axios(config);
    const { data, status, statusText } = response;

    if (status >= 400 && status < 600) {
      if (status < 500) {
        throw new Error(`${status} ${statusText} error`);
      } else {
        throw new Error(`${status} ${statusText} error`);
      }
    }

    return data;
  },
});
