import { useGeneratedId } from '@rocketmakers/armstrong-edge';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';

import { Video } from './video';

import { SVG } from '../utils/svg';

import './hero.scss';

const getRoundedCornerPath = (lineEndY: number, lineEndXFromCentre: number, curveStartY: number) =>
  SVG.svgPathToString([
    { type: 'MOVE', x: 0, y: 0 },
    { type: 'LINE', x: 1, y: 0 },
    { type: 'LINE', x: 1, y: lineEndY },
    { type: 'LINE', x: 0.5 + lineEndXFromCentre, y: curveStartY },
    { type: 'QUADRATIC', control: { x: 0.5, y: 1 }, x: 0.5 - lineEndXFromCentre, y: curveStartY },
    { type: 'LINE', x: 0, y: lineEndY },
    { type: 'CLOSE' },
  ]);

interface IHeroProps {
  backgroundVideoUrl?: string;
  backgroundVideoImageFallbacks?: IGatsbyImageData[];
  backgroundImage?: IGatsbyImageData;
  darkenBackground?: boolean;
  shape?: 'round' | 'rounded_corner' | 'square';

  /** JSX for right half of foreground - for right, use children, to swap use swapSides */
  left?: React.ReactNode;
  swapSides?: boolean;
  overlay?: JSX.Element;
}

export const Hero: React.FunctionComponent<React.PropsWithChildren<IHeroProps>> = ({
  children,
  backgroundImage,
  backgroundVideoUrl,
  darkenBackground,
  shape,
  left,
  overlay,
  backgroundVideoImageFallbacks,
}) => {
  const svgPathId = useGeneratedId();

  return (
    <>
      {shape === 'rounded_corner' && (
        <svg className="hero-path-svg" viewBox="0 0 1 1">
          <defs>
            <clipPath id={svgPathId} clipPathUnits="objectBoundingBox">
              <path d={getRoundedCornerPath(0.75, 0.17, 0.93)} />
            </clipPath>
            <clipPath id={`${svgPathId}_mobile`} clipPathUnits="objectBoundingBox">
              <path d={getRoundedCornerPath(0.85, 0.2, 0.94)} />
            </clipPath>
          </defs>
        </svg>
      )}

      <section
        className="hero"
        style={
          {
            '--svg-path-id': `url(#${svgPathId})`,
            '--svg-path-id-mobile': `url(#${svgPathId}_mobile)`,
          } as React.CSSProperties
        }
        data-darken-background={darkenBackground}
        data-shape={shape}
      >
        <div className="hero-content">
          <div className="hero-background">
            {backgroundImage && !backgroundVideoUrl && <GatsbyImage image={backgroundImage} alt="" />}
            {backgroundVideoUrl && (
              <Video
                fill
                videoUrl={backgroundVideoUrl}
                fallbackImages={backgroundVideoImageFallbacks || (backgroundImage && [backgroundImage])}
              />
            )}
          </div>
          <div className="hero-content-foreground">
            <div className="hero-content-left">{left}</div>
            <div className="hero-content-right">
              <div className="hero-content-copy">{children}</div>
            </div>
          </div>
        </div>

        {overlay}
      </section>
    </>
  );
};
Hero.defaultProps = {
  darkenBackground: true,
  shape: 'rounded_corner',
};
