import { Routes } from './src/utils/routes';

export interface IPrismicDocument {
  type: string;
  uid?: string;
  id: string;
}

// define routes so that links to prismic documents are hooked up

export const linkResolver = (doc: IPrismicDocument) => {
  switch (doc.type) {
    case 'feature':
      return Routes.feature(doc.uid);
    case 'demo':
      return Routes.demo(doc.uid);
    case 'industry':
      return Routes.industry(doc.uid);
    case 'faqs':
    case 'get_started':
      return Routes.getStarted;
    case 'get_in_touch':
      return Routes.getInTouch;
    case 'about_us':
    case 'about':
      return Routes.aboutUs;
    default:
      return Routes.home;
  }
};
