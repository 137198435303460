import { Image, Spinner, useDidMountEffect, useTemporaryState } from '@rocketmakers/armstrong-edge';
import * as React from 'react';

import { Assets } from '../content/assets';
import { useBeamingState } from '../hooks/useBeamingState';
import { MathsUtils } from '../utils/maths';
import { Beam } from './beam';

import './featureGraphicCar.scss';

interface ICar {
  name: string;
  modelPath: string;
  imageAssetName: string;
}

export const cars: ICar[] = [
  { name: 'Sedan', modelPath: Assets.carModels.car5, imageAssetName: 'sedan' },
  { name: 'Muscle', modelPath: Assets.carModels.car2, imageAssetName: 'muscle' },
  { name: 'Sports', modelPath: Assets.carModels.car3, imageAssetName: 'sports' },
];

/** logic for timing of cars swapping in and out, optionally at random */
export const useFeatureGraphicCarAnimation = (cycle?: boolean) => {
  // used to ensure car gets unmounted when it changes
  const [changingCar, setChangingCar] = useTemporaryState(false, 100);

  // path to the current model
  const [currentAsset, setCurrentAsset, { beamingIn, beamingOut }] = useBeamingState<ICar | undefined>(
    MathsUtils.randomFromArray(cars),
    () => setChangingCar(true)
  );

  const timeout = React.useRef<NodeJS.Timeout>();

  // choose a random model
  const pickRandomContent = React.useCallback(() => {
    setCurrentAsset(MathsUtils.randomFromArray(cars));
    if (cycle) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(pickRandomContent, MathsUtils.randomBetween(3000, 6000));
    }
  }, [currentAsset, setCurrentAsset]);

  useDidMountEffect(() => {
    if (cycle) {
      timeout.current = setTimeout(pickRandomContent, MathsUtils.randomBetween(3000, 6000));
    }
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  });

  return {
    beamingIn,
    beamingOut,
    pickRandomContent,
    currentAsset,
    changingCar,
    setCurrentAsset,
  };
};

export interface IFeatureGraphicCarProps {
  cycle?: boolean;
}

/** logic spun out of featureGraphicCar, but with images only (moving to a separate component ensures that we don't import three unless we need it) */
export const FeatureGraphicCarImage: React.FunctionComponent<IFeatureGraphicCarProps> = ({ cycle }) => {
  const { beamingIn, beamingOut, pickRandomContent, changingCar, currentAsset } = useFeatureGraphicCarAnimation(cycle);

  const wrapperRef = React.useRef<HTMLDivElement>(null);

  return (
    <div
      className="feature-graphic-car"
      data-beaming-in={beamingIn}
      data-beaming-out={beamingOut}
      ref={wrapperRef}
      aria-hidden
      tabIndex={-1}
    >
      <div className="feature-graphic-car-inner" onClick={pickRandomContent}>
        {currentAsset && !changingCar && currentAsset.imageAssetName && (
          <div className="feature-graphic-car-image-wrapper">
            <Image
              wrapperAttributes={{ className: 'feature-graphic-car-image' }}
              src={Assets.carImages[currentAsset.imageAssetName]}
              alt=""
              additionalSources={[
                { srcSet: Assets.carImagesSmall[currentAsset.imageAssetName] },
                { srcSet: Assets.carImagesSmallWebp[currentAsset.imageAssetName] },
              ]}
            />
          </div>
        )}

        {changingCar && <Spinner />}

        <FeatureGraphicCarImageBase />
      </div>
    </div>
  );
};
export interface IFeatureGraphicCarImageBaseProps {
  beamingIn?: boolean;
  beamingOut?: boolean;
}

export const FeatureGraphicCarImageBase: React.FC<React.PropsWithChildren<IFeatureGraphicCarImageBaseProps>> = ({
  beamingIn,
  beamingOut,
  children,
}) => {
  return (
    <div className="feature-graphic-car-base-wrapper">
      <div className="feature-graphic-car-base">
        <img src={Assets.carBase} alt="" />

        <Beam beamingIn={beamingIn} beamingOut={beamingOut} />

        {children}
      </div>
    </div>
  );
};
