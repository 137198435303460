import * as React from 'react';

import './demoType.scss';

interface IDemoTypeProps {
  type?: string;
}

export const DemoType: React.FunctionComponent<IDemoTypeProps> = ({ type }) => {
  return (
    <div className="demo-type">
      <span>{type}</span>
    </div>
  );
};
