import { graphql, PageProps } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';

import { Arrow } from '../components/arrow';
import { CopySection } from '../components/copySection';
import { FeatureGraphic, IFeatureGraphicProps } from '../components/featureGraphic';
import { Footer } from '../components/footer';
import { Hero } from '../components/hero';
import { LinkWrapper } from '../components/linkWrapper';
import { RichText } from '../components/richText';
import { View } from '../components/view';
import { FeatureQuery, PrismicFeature, PrismicFeatureDataBodyCopyBlock } from '../generated/graphql';
import { Routes } from '../utils/routes';

import './feature.scss';

const FeatureView: React.FC<PageProps<FeatureQuery>> = ({ data }) => {

  return (
    <View
      className="feature-view"
      title={data.prismicFeature?.data.name || undefined}
      description={data.prismicFeature?.data.subtitle || undefined}
    >
      <Hero
        darkenBackground={false}
        overlay={<FeatureGraphic type={(data.prismicFeature?.data.graphic as IFeatureGraphicProps['type']) || 'car'} />}
      >
        <h2>{data.prismicFeature?.data.subtitle}</h2>
        {data.prismicFeature?.data.short_description?.richText && (
          <RichText field={data.prismicFeature?.data.short_description.richText} />
        )}
      </Hero>

      {data.prismicFeature?.data.body.map((slice, index) => (
        <CopySection
          content={(slice as PrismicFeatureDataBodyCopyBlock).primary.content?.richText || []}
          image={(slice as PrismicFeatureDataBodyCopyBlock).primary.image?.gatsbyImageData}
          text_side={(slice as PrismicFeatureDataBodyCopyBlock).primary.text_side || undefined}
          variant={(slice as PrismicFeatureDataBodyCopyBlock).primary.variant || undefined}
          title={(slice as PrismicFeatureDataBodyCopyBlock).primary.title || undefined}
          key={(slice as PrismicFeatureDataBodyCopyBlock).primary.title || `${index}`}
          video_url={(slice as PrismicFeatureDataBodyCopyBlock).primary.video_url || undefined}
          animation={(slice as PrismicFeatureDataBodyCopyBlock).primary.animation || undefined}
        />
      ))}

      <div className="feature-pagination">
        <div className="feature-pagination-inner">
          <div className="feature-pagination-prev">
            {(data.prismicFeature?.data.previous?.document as PrismicFeature)?.data && (
              <LinkWrapper
                to={Routes.feature((data.prismicFeature?.data.previous?.document as PrismicFeature)?.uid || '')}
              >
                <p className="feature-name">
                  {(data.prismicFeature?.data.previous?.document as PrismicFeature).data.name}
                </p>
                <p>{(data.prismicFeature?.data.previous?.document as PrismicFeature).data.subtitle}</p>
                <div className="feature-pagination-arrow-wrapper">
                  <Arrow direction="left" />
                </div>
              </LinkWrapper>
            )}
          </div>
          <div className="feature-pagination-next">
            {(data.prismicFeature?.data.next?.document as PrismicFeature)?.data ? (
              <LinkWrapper to={Routes.feature((data.prismicFeature?.data.next?.document as PrismicFeature)?.uid)}>
                <p className="feature-name">{(data.prismicFeature?.data.next?.document as PrismicFeature).data.name}</p>
                <p>{(data.prismicFeature?.data.next?.document as PrismicFeature).data.subtitle}</p>

                <div className="feature-pagination-arrow-wrapper next-page">
                  <p>next page</p>
                  <Arrow direction="right" />
                </div>
              </LinkWrapper>
            ) : (
              <LinkWrapper to={Routes.getStarted}>
                <p className="feature-name">Get started</p>
                <p>Interested in using Beam in something you&apos;re working on?</p>

                <div className="feature-pagination-arrow-wrapper next-page">
                  <p>next page</p>
                  <Arrow direction="right" />
                </div>
              </LinkWrapper>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </View>
  );
};

export const pageQuery = graphql`
  query Feature($id: String!) {
    prismicFeature(id: { eq: $id }) {
      _previewable
      data {
        body {
          ... on PrismicFeatureDataBodyCopyBlock {
            id
            primary {
              image {
                gatsbyImageData(width: 600, placeholder: BLURRED)
              }
              content {
                richText
              }
              video_url
              variant
              text_side
              title
              animation
            }
          }
        }
        graphic
        name
        subtitle
        short_description {
          richText
        }
        next {
          document {
            ... on PrismicFeature {
              uid
              id
              data {
                subtitle
                name
              }
            }
          }
        }
        previous {
          document {
            ... on PrismicFeature {
              uid
              id
              data {
                subtitle
                name
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(FeatureView);
