import * as React from 'react';
import { useTemporaryState, useWillUnMountEffect } from "@rocketmakers/armstrong-edge";

export const useTimeout = (waitTime: number, callback?: () => void) => {
    const [changing, setChanging] = useTemporaryState(false, 300);

    const timeout = React.useRef<NodeJS.Timeout>();
    useWillUnMountEffect(() => timeout.current && window.clearTimeout(timeout.current));
    React.useEffect(() => {
      timeout.current = setTimeout(() => {
      setChanging(true);
      if(callback){
        callback();
      } 
      }, waitTime);
    
      return () => {
      if (timeout.current) {
          window.clearTimeout(timeout.current);
      }
      };
    }, [changing]);

    return { changing }
}
