import * as React from 'react';

import { PrismicFeature } from '../generated/graphql';
import { Card } from './card';
import { FeatureGraphicAnalyticsImage } from './featureGraphicAnalyticsImage';
import { FeatureGraphicCarImage } from './featureGraphicCarImage';
import { FeatureGraphicIsometric } from './featureGraphicIsometric';
import { LearnMore } from './learnMore';

import './featureCard.scss';

interface IFeatureCardProps {
  name?: string;
  subtitle?: string;
  to?: string;
  graphicVariant?: PrismicFeature['data']['graphic'];
}

export const FeatureCard: React.FunctionComponent<IFeatureCardProps> = ({ name, subtitle, graphicVariant, to }) => {
  return (
    <Card className="feature-card" to={to} anchorProps={{ className: 'feature-card-wrapper' }}>
      <p className="feature-card-name">{name}</p>
      <p className="feature-card-subtitle">{subtitle}</p>

      <div className="feature-card-graphic">
        {graphicVariant === 'car' && <FeatureGraphicCarImage cycle />}
        {graphicVariant === 'isometric' && (
          <FeatureGraphicIsometric squareOnly gridSize={{ x: 1, y: 1 }} populatedTiles={1} />
        )}
        {graphicVariant === 'heatmap' && <FeatureGraphicAnalyticsImage />}
      </div>

      <LearnMore />
    </Card>
  );
};
