import { useMatchMedia } from '@rocketmakers/armstrong-edge';

// ENSURE THESE ARE KEPT UP TO DATE WITH THE ONES IN theme/shared/variables.scss
export const screenSizes = {
  small: 738,
  medium: 1000,
};

export const useScreenSize = () => {
  const large = useMatchMedia(`(min-width: ${screenSizes.medium}px)`);
  const medium = useMatchMedia(`(min-width: ${screenSizes.small}px)`);

  if (large) {
    return 'large';
  }

  if (medium) {
    return 'medium';
  }

  return 'small';
};
