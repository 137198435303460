import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';

import './fadingImage.scss';

interface IFadingImageProps {
  images: IGatsbyImageData[];
  timeout?: number;
}

export const FadingImage: React.FunctionComponent<IFadingImageProps> = ({ images }) => {
  const [currentImage, setCurrentImage] = React.useState(0);

  const timeout = React.useRef<NodeJS.Timeout>();
  React.useEffect(() => {
    if (images.length > 1) {
      timeout.current = setTimeout(() => {
        setCurrentImage(currentImage === images.length - 1 ? 0 : currentImage + 1);
      }, 2000);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [currentImage]);

  return (
    <div className="fading-image" aria-hidden="true">
      {images.map((image, index) => (
        <div
          className="fading-image-image"
          key={image.images.sources?.[0]?.srcSet || index}
          data-current={currentImage === index}
        >
          <GatsbyImage image={image} alt="" />
        </div>
      ))}
    </div>
  );
};

FadingImage.defaultProps = {
  timeout: 2000,
};
