import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { MetaQuery } from '../generated/graphql';

export interface IMetaProps {
  title?: string;
  description?: string;
  imageUrl?: string;
}

const getMeta = ({ title, description, imageUrl }: IMetaProps) =>
  [
    { name: 'description', content: description },
    { name: 'og:title', content: title },
    { name: 'og:description', content: description },
    { name: 'og:image', content: imageUrl },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image', content: imageUrl },
  ].filter(item => !!item.content);

export const Meta: React.FC<IMetaProps> = ({ description, imageUrl, title }) => {
  return <Helmet title={title} meta={getMeta({ description, title, imageUrl })} />;
};

export const RootMeta: React.FC = () => {
  const data = useStaticQuery<MetaQuery>(
    graphql`
      query Meta {
        prismicMetadata {
          data {
            description
            title
            image {
              url
            }
          }
        }
      }
    `
  );
  const title = data.prismicMetadata?.data?.title || undefined;
  const description = data.prismicMetadata?.data.description || undefined;
  const imageUrl = data.prismicMetadata?.data.image?.url || undefined;

  return (
    <Helmet titleTemplate={`%s | ${title}`} defaultTitle={title} meta={getMeta({ title, description, imageUrl })} />
  );
};
