import { PrismicProvider } from '@prismicio/react';
import { ArmstrongConfigProvider, ToastProvider } from '@rocketmakers/armstrong-edge';
import { GatsbyBrowser, Link, navigate } from 'gatsby';
import { componentResolverFromMap, PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';

import { linkResolver } from '../linkResolver';
import Shell, { IShellProps } from './components/shell';
import IndexView from './pages';
import AboutUsView from './pages/about';
import GetInTouchView from './pages/get-in-touch';
import GetStartedView from './pages/get-started';
import DemoView from './templates/demo';
import FeatureView from './templates/feature';
import IndustryView from './templates/industry';

export const Providers: React.FC<React.PropsWithChildren<IShellProps>> = ({ children, headerVariant }) => {
  return (
    <ArmstrongConfigProvider
      routing={{
        LinkComponent: ({ to, ...props }) => (to[0] === '/' ? <Link to={to} {...props} /> : <a href={to} {...props} />),
        navigate: (to, action) => navigate(to, { replace: action === 'replace' }),
      }}
    >
      <PrismicPreviewProvider
        repositoryConfigs={[
          {
            linkResolver,
            repositoryName: process.env.PRISMIC_REPO_NAME as string,
            componentResolver: componentResolverFromMap({
              feature: FeatureView,
              demo: DemoView,
              industry: IndustryView,
              faqs: GetStartedView,
              get_started: GetStartedView,
              get_in_touch: GetInTouchView,
              about_us: AboutUsView,
              home: IndexView,
            }),
          },
        ]}
      >
        <PrismicProvider linkResolver={linkResolver}>
          <ToastProvider autoDismissTime={5000}>
            <Shell headerVariant={headerVariant}>{children}</Shell>
          </ToastProvider>
        </PrismicProvider>
      </PrismicPreviewProvider>
    </ArmstrongConfigProvider>
  );
};

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => (
  <Providers headerVariant="white">{element}</Providers>
);
