import * as React from 'react';

import './arrow.scss';

interface IArrowProps {
  direction: 'left' | 'right';
}

export const Arrow: React.FunctionComponent<IArrowProps> = ({ direction }) => {
  return <div className="arrow" data-direction={direction} />;
};
