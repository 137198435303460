import { Arrays, Button } from '@rocketmakers/armstrong-edge';
import * as React from 'react';

import { Arrow } from './arrow';

import './pagination.scss';

interface IPaginationProps {
  pages: number;
  currentPage: number;
  onSetSlide?: (newIndex: number) => void;
}

export const Pagination: React.FunctionComponent<IPaginationProps> = ({ onSetSlide, pages, currentPage }) => {
  return (
    <div className="pagination">
      <Button
        className="pagination-button pagination-prev"
        name="Previous Slide"
        aria-label="Previous Slide"
        onClick={() => currentPage > 0 && onSetSlide?.(currentPage - 1)}
        data-hidden={currentPage === 0}
      >
        <Arrow direction="left" />
      </Button>
      {Arrays.repeat(pages, index => (
        <Button
          className="pagination-bullet"
          key={index}
          data-current={currentPage === index}
          data-previous={currentPage > index}
          data-next={currentPage < index}
          onClick={() => onSetSlide?.(index)}
          aria-label={`Go to ${index}`}
        />
      ))}
      <Button
        className="pagination-button pagination-next"
        aria-label="Next Slide"
        onClick={() => currentPage < pages - 1 && onSetSlide?.(currentPage + 1)}
        data-hidden={currentPage === pages - 1}
      >
        <Arrow direction="right" />
      </Button>
    </div>
  );
};
