import { useLocation } from '@reach/router';
import { Globals, useEventListener } from '@rocketmakers/armstrong-edge';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { HeaderQuery } from '../generated/graphql';
import { Routes } from '../utils/routes';
import { Hamburger } from './hamburger';
import { HamburgerButton } from './hamburgerButton';
import { LinkWrapper } from './linkWrapper';

import './header.scss';

export interface IHeaderProps {
  variant: 'white' | 'black';
}

export const Header: React.FunctionComponent<IHeaderProps> = ({ variant }) => {
  const query = useStaticQuery<HeaderQuery>(graphql`
    query Header {
      prismicHeader {
        data {
          logo_white {
            gatsbyImageData(width: 100, layout: CONSTRAINED)
          }
          logo {
            gatsbyImageData(width: 100, layout: CONSTRAINED)
          }
          link {
            highlighted
            name
            to {
              url
            }
          }
        }
      }
    }
  `);

  const [hamburgerOpen, setHamburgerOpen] = React.useState(false);

  // if mounted on server (so window is undefined) assume scrolled at top
  const [atTop, setAtTop] = React.useState(Globals.Window ? Globals.Window?.scrollY < 5 : true);
  const getIsAtTop = React.useCallback(() => {
    setAtTop(!!Globals.Window && Globals.Window.scrollY < 5);
  }, []);
  useEventListener('scroll', getIsAtTop);
  const { pathname } = useLocation();
  React.useLayoutEffect(() => {
    getIsAtTop();
    setHamburgerOpen(false);
  }, [pathname]);

  return (
    <>
      <Hamburger isOpen={hamburgerOpen} />

      <header className="bm-header" data-variant={variant} data-at-top={atTop} data-hamburger-open={hamburgerOpen}>
        <Link to={Routes.home} className="bm-header-logo" aria-label="Home">
          <div className="bm-header-logo">
            <GatsbyImage
              className="bm-header-logo-white"
              alt="logo"
              aria-hidden={true}
              image={query.prismicHeader?.data.logo_white?.gatsbyImageData}
            />
            <GatsbyImage
              className="bm-header-logo-black"
              alt="logo"
              aria-hidden={true}
              image={query.prismicHeader?.data.logo?.gatsbyImageData}
            />
          </div>
        </Link>

        <nav className="bm-header-links">
          <ul>
            {query.prismicHeader?.data.link?.map(link => (
              <li key={link?.name} className="bm-header-link">
                <LinkWrapper to={link?.to?.url || undefined}>
                  <span>{link?.name}</span>
                </LinkWrapper>
              </li>
            ))}

            <li>
              <HamburgerButton isOpen={hamburgerOpen} onClick={() => setHamburgerOpen(!hamburgerOpen)} />
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};
