import { PrismicRichTextProps } from '@prismicio/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';
import Measure from 'react-measure';
import { Autoplay, Swiper as SwiperCore } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Routes } from '../utils/routes';
import { Card } from './card';
import { LearnMore } from './learnMore';
import { Pagination } from './pagination';
import { SectionTitle } from './sectionTitle';
import { Video } from './video';

import './industriesSwiper.scss';

interface IIndustriesSwiperProps {
  description?: PrismicRichTextProps['field'];
  title?: string;
  industries?: Array<{
    name?: string;
    icon?: IGatsbyImageData;
    to?: string;
    videoUrl?: string;
    coverImage?: IGatsbyImageData;
    uid?: string;
  }>;
}

export const IndustriesSwiper: React.FunctionComponent<IIndustriesSwiperProps> = ({
  industries,
  description,
  title,
}) => {
  const swiper = React.useRef<SwiperCore | undefined>(undefined);

  const [currentSlide, setCurrentSlide] = React.useState(0);

  const currentIndustry = industries?.[currentSlide];

  const [containerSize, setContainerSize] = React.useState(1024);

  const slidesPerView = Math.floor(containerSize / 240);

  return (
    <div className="industries-swiper">
      <div className="industries-swiper-inner">
        <div className="industries-swiper-background">
          {currentIndustry?.videoUrl && (
            <Video
              fill
              videoUrl={currentIndustry.videoUrl}
              fallbackImages={currentIndustry.coverImage && [currentIndustry.coverImage]}
            />
          )}
        </div>

        {title && <SectionTitle title={title} description={description} />}

        <Measure onResize={({ client }) => setContainerSize(client?.width || 0)} client>
          {({ measureRef }) => (
            <div className="industries-swiper-swiper-wrapper" ref={measureRef}>
              <Swiper
                modules={[Autoplay]}
                onInit={sw => {
                  swiper.current = sw;
                }}
                slidesPerView={slidesPerView}
                onSlideChange={sw => setCurrentSlide(sw.realIndex)}
                autoplay={{
                  delay: 3000,
                }}
                centeredSlides
              >
                {industries?.map((industry, index) => (
                  <SwiperSlide key={(industry.name || '') + index}>
                    <Card
                      className="industry-card"
                      to={industry.uid && Routes.industry(industry.uid)}
                      anchorProps={{ className: 'industry-card-wrapper' }}
                    >
                      {industry.icon && <GatsbyImage className="industry-card-icon" image={industry.icon} alt="" />}
                      <p className="industry-card-name">{industry.name}</p>
                      <LearnMore />
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </Measure>
      </div>

      {swiper.current && (
        <Pagination
          pages={industries?.length || 0}
          currentPage={currentSlide}
          onSetSlide={slide => swiper.current?.slideTo(slide)}
        />
      )}
    </div>
  );
};
