import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';

import { CopySection } from '../components/copySection';
import { Footer } from '../components/footer';
import { Hero } from '../components/hero';
import { IndustriesSwiper } from '../components/industriesSwiper';
import { RichText } from '../components/richText';
import { View } from '../components/view';
import { IndustryQuery, PrismicIndustry, PrismicIndustryDataBodyCopyBlock } from '../generated/graphql';
import { Routes } from '../utils/routes';

import './industry.scss';

const IndustryView: React.FC<PageProps<IndustryQuery>> = ({ data }) => {
  return (
    <View
      className="industry-view"
      title={data.prismicIndustry?.data.name || undefined}
      description={data.prismicIndustry?.data.hero_text?.text || undefined}
    >
      <Hero
        shape="square"
        backgroundImage={data.prismicIndustry?.data.hero_image?.gatsbyImageData}
        backgroundVideoUrl={data.prismicIndustry?.data.vimeo_url || undefined}
      >
        <div className="industry-view-title">
          <GatsbyImage image={data.prismicIndustry?.data.icon?.gatsbyImageData} alt="" />
          <h1>{data.prismicIndustry?.data.name}</h1>
        </div>
        {data.prismicIndustry?.data.hero_text?.richText && (
          <RichText field={data.prismicIndustry?.data.hero_text?.richText} />
        )}
      </Hero>

      <div className="industry-view-content">
        {data.prismicIndustry?.data.body.map((slice, index) => (
          <CopySection
            content={(slice as PrismicIndustryDataBodyCopyBlock).primary.content?.richText || []}
            image={(slice as PrismicIndustryDataBodyCopyBlock).primary.image?.gatsbyImageData}
            text_side={(slice as PrismicIndustryDataBodyCopyBlock).primary.text_side || undefined}
            variant={(slice as PrismicIndustryDataBodyCopyBlock).primary.variant || undefined}
            title={(slice as PrismicIndustryDataBodyCopyBlock).primary.title || undefined}
            key={(slice as PrismicIndustryDataBodyCopyBlock).primary.title || index}
            video_url={(slice as PrismicIndustryDataBodyCopyBlock).primary.video_url || undefined}
            animation={(slice as PrismicIndustryDataBodyCopyBlock).primary.animation || undefined}
          />
        ))}
      </div>

      <IndustriesSwiper
        industries={
          data.prismicHomePage?.data.industries?.map(industry => ({
            coverImage: (industry?.industry?.document as PrismicIndustry).data.hero_image?.gatsbyImageData || undefined,
            videoUrl: (industry?.industry?.document as PrismicIndustry).data.vimeo_url || undefined,
            name: (industry?.industry?.document as PrismicIndustry).data.name || undefined,
            icon: (industry?.industry?.document as PrismicIndustry).data.icon?.gatsbyImageData || undefined,
            description: (industry?.industry?.document as PrismicIndustry).data.hero_text?.richText || undefined,
            to: Routes.industry((industry?.industry?.document as PrismicIndustry).uid) || undefined,
            uid: industry?.industry?.uid || undefined,
          })) || []
        }
      />

      <Footer />
    </View>
  );
};

export const pageQuery = graphql`
  query Industry($id: String!) {
    prismicIndustry(id: { eq: $id }) {
      _previewable
      data {
        body {
          ... on PrismicIndustryDataBodyCopyBlock {
            id
            primary {
              image {
                gatsbyImageData(width: 600, placeholder: BLURRED)
              }
              content {
                richText
                text
              }
              video_url
              variant
              title
              text_side
              animation
            }
          }
        }
        hero_image {
          gatsbyImageData(width: 1024, placeholder: BLURRED)
        }
        hero_text {
          richText
          text
        }
        icon {
          gatsbyImageData(width: 200, placeholder: BLURRED)
        }
        name
        vimeo_url
      }
    }
    prismicHomePage {
      _previewable
      data {
        industries {
          industry {
            uid
            document {
              ... on PrismicIndustry {
                id
                data {
                  name
                  hero_text {
                    text
                  }
                  vimeo_url
                  hero_image {
                    gatsbyImageData(width: 1024, placeholder: BLURRED)
                  }
                  icon {
                    gatsbyImageData(width: 100, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(IndustryView);
