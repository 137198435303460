import { PrismicRichText, PrismicRichTextProps } from '@prismicio/react';
import * as React from 'react';

import './richText.scss';

export const RichText: React.FC<PrismicRichTextProps> = props => {
  return (
    <div className="bm-rich-text">
      <PrismicRichText {...props} />
    </div>
  );
};
