import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import * as React from 'react';

import { LinkWrapper } from './linkWrapper';

import './platforms.scss';

export interface IPlatformsProps {
  platforms?: Array<{ url?: string; image?: IGatsbyImageData; name?: string }>;
}

export const Platforms: React.FC<IPlatformsProps> = ({ platforms }) => {
  return (
    <div className="platforms">
      {platforms?.map(platform => (
        <LinkWrapper key={platform.url} to={platform.url} name={platform.name || ''}>
          {platform.image && <GatsbyImage image={platform.image} alt={platform.name || ''} />}
        </LinkWrapper>
      ))}
    </div>
  );
};
