import * as React from 'react';
import { MathsUtils } from '../utils/maths';
import { SvgIcons } from './svgIcon';

export type GraphicsCardVariant = 'delivered' | 'interaction' | 'gaze' | 'users' | 'conversion'
export const GraphicsCardVariantList: GraphicsCardVariant[] = ['delivered', 'interaction', 'gaze', 'users', 'conversion'];

import './featureGraphicAnalyticsCard.scss'
import { useCountUp } from 'react-countup';
import { useDidUpdateEffect } from '@rocketmakers/armstrong-edge';

interface IFeatureGraphicAnalyticsCard {
  percentageChangeEnd: number;
  actualEnd: number;
  variant: GraphicsCardVariant;
  className?: string;
  fixedPosition?: {left?: number, bottom?: number};
  countUpDelay?: number;
  countUpDuration?: number;
}

export const FeatureGraphicAnalyticsCard: React.FC<IFeatureGraphicAnalyticsCard> = ({ 
    actualEnd, 
    percentageChangeEnd, 
    variant, 
    className, 
    fixedPosition,
    countUpDelay,
    countUpDuration = 0.2
  }) => {
  const [title, setTitle] = React.useState("");

  const actualRef = React.useRef(null);
  const percentageRef = React.useRef(null);

  const { update: updateActual } = useCountUp({
    start: 0, 
    end: actualEnd > 1000 ? actualEnd / 1000 : actualEnd,
    decimals: actualEnd > 1000 ? 1 : 0,
    ref: actualRef, 
    delay: countUpDelay || 0, 
    duration: countUpDuration,
    suffix: actualEnd > 1000 ? "k" : ""});
  const { update: updatePercent} = useCountUp({
    start: 0, 
    end: percentageChangeEnd, 
    ref: percentageRef, 
    delay: countUpDelay || 0, 
    duration: countUpDuration,
    prefix: percentageChangeEnd > 0 ? "+" : "",
    suffix: "%"});

  useDidUpdateEffect(() => {
    updateActual(actualEnd);
  }, [actualEnd]);

  useDidUpdateEffect(() => {
    updatePercent(percentageChangeEnd);
  }, [percentageChangeEnd])

  const left = React.useMemo(() => fixedPosition && fixedPosition.left || MathsUtils.randomBetweenRanges([[0, 20], [55, 65]], 0.1), [variant]);
  const bottom = React.useMemo(() => fixedPosition && fixedPosition.bottom || MathsUtils.randomBetweenRanges([[65, 75], [10, 25]], 0.1), [variant]);

  const iconVariant = React.useMemo(() => {
    switch(variant) {
      case 'delivered':
          setTitle('Assets delivered');
        return <SvgIcons.AssetsDelivered />
      case 'interaction': 
        setTitle('Interactions');
        return <SvgIcons.Hand />
      case 'gaze': 
        setTitle('Gazes');
        return <SvgIcons.Eye />
      case 'users':
        setTitle('Unique users');
        return <SvgIcons.Gamepad />
      case 'conversion':
        setTitle('Conversions');
        return <SvgIcons.Conversions />
    }
  }, [variant]);

  return (
    <div className={`feature-graphic-analytics-card ${className || ''}`} style={
      {
        '--left': `${left}%`,
        '--bottom': `${bottom}%`,
      } as React.CSSProperties
    } >
      <h3>{title}</h3>
      <div className="feature-graphic-analytics-card-details">
        {iconVariant}
        <div className="feature-graphic-analytics-card-details-information">
          <div className="number-of" ref={actualRef} />
          <div className={`percentage-of ${percentageChangeEnd >= 0 ? 'positive' : 'negative'}`} ref={percentageRef} />
        </div>
      </div>
    </div>
  )
}
