import * as React from 'react';

import './svgIcon.scss';

// using these to avoid having to load Icomoon

export const SvgIcon: React.FunctionComponent<React.PropsWithChildren<{viewBox?: string}>> = ({ viewBox, children }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox={viewBox || "0 0 30 30"}
      className="svg-icon"
    >
      {children}
    </svg>
  );
};

export const SvgIconGradient: React.FC = () => (  
    <linearGradient id="linear_gradient_1" x1="26.5323" y1="0" x2="26.5323" y2="35" gradientUnits="userSpaceOnUse">
      <stop stopColor="#3BDBFE"/>
      <stop offset="1" stopColor="#4A63F7"/>
    </linearGradient>
)

export const SvgIcons = {
  Link: () => (
    <SvgIcon>
      <g>
        <path
          d="M4.9,28.6c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.1-0.6,0.2-0.9,0.2c-0.3,0-0.6-0.1-0.9-0.2C2,29,1.7,28.8,1.5,28.6
		c-0.1-0.1-0.2-0.2-0.3-0.4S1.1,28,1,27.8c0-0.1-0.1-0.3-0.1-0.4s0-0.3,0-0.5c0-0.1,0-0.3,0-0.5S1,26.2,1,26
		c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.3-0.4L21,5.7h-8.4c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.8-0.5
		c-0.2-0.2-0.4-0.5-0.5-0.8s-0.2-0.6-0.2-0.9s0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.5-0.8c0.2-0.2,0.5-0.4,0.8-0.5
		C12,1.1,12.3,1,12.6,1h14.1c0.1,0,0.1,0,0.2,0s0.2,0,0.2,0c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.4,0.2,0.6,0.3
		c0.2,0.1,0.3,0.3,0.5,0.5c0.1,0.2,0.2,0.4,0.3,0.6s0.1,0.5,0.1,0.7v14.1c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.3,0.5-0.5,0.8
		c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.1-0.6,0.2-0.9,0.2s-0.6-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.8-0.5c-0.2-0.2-0.4-0.5-0.5-0.8
		c-0.1-0.3-0.2-0.6-0.2-0.9V9.1L4.9,28.6z"
        />
      </g>
    </SvgIcon>
  ),
  Down: () => (
    <SvgIcon>
      <g>
        <path d="M29.4,12.4L15,26.9L0.5,12.4l2.7-2.7L15,21.5L26.7,9.7L29.4,12.4z" />
      </g>
    </SvgIcon>
  ),

  Question: () => (
    <SvgIcon>
      <g>
        <path
          d="M15,0.3c2,0,4,0.4,5.8,1.2c1.8,0.8,3.4,1.8,4.7,3.2c1.3,1.3,2.4,2.9,3.2,4.7s1.2,3.7,1.2,5.8c0,2-0.4,4-1.2,5.8
		c-0.8,1.8-1.8,3.4-3.2,4.7c-1.3,1.3-2.9,2.4-4.7,3.2C19,29.6,17.1,30,15,30c-2,0-4-0.4-5.8-1.2S5.9,27,4.5,25.6
		c-1.3-1.3-2.4-2.9-3.2-4.7c-0.8-1.8-1.2-3.7-1.2-5.8c0-2,0.4-4,1.2-5.8S3.2,6,4.5,4.7s2.9-2.4,4.7-3.2S13,0.3,15,0.3z M22.4,15.2
		V9.6c0-0.3,0-0.5-0.1-0.7s-0.2-0.4-0.4-0.6c-0.2-0.2-0.4-0.3-0.6-0.4c-0.2-0.1-0.5-0.1-0.7-0.1H9.5v3.7h9.3v1.9L13.2,17v1.9h3.7
		L22.4,15.2z M16.9,24.4v-3.7h-3.7v3.7H16.9z"
        />
      </g>
    </SvgIcon>
  ),
  AssetsDelivered: () => (
    <SvgIcon viewBox='-5 -5 70 70'>
      <defs>
        <SvgIconGradient />
      </defs>
      <g>
        <path className="gradient-fill" d="M42.7071,29.4775L57.5016,14.4167C57.8188,14.0941,58,13.6562,58,13.1953C58,12.7343,57.8188,12.2964,57.5016,11.9738L46.1735,
          0.508208C45.5052,-0.160138,44.4403,-0.160138,43.772,0.508208L29.0002,15.5345L16.6299,2.95113C16.3807,2.69762,16.0635,2.53629,15.7237,
          2.46715L1.99406,0.0242334C1.45032,-0.0679522,0.883914,0.104896,0.49876,0.508208C0.113606,0.91152,-0.0676434,1.47616,0.0229811,
          2.02927L2.42453,15.9954C2.48117,16.3411,2.65109,16.6637,2.90031,16.9173L15.2706,29.4891L0.49876,44.5269C0.181574,44.8495,0.000324936,
          45.2874,0.000324936,45.7483C0.000324936,46.2092,0.181574,46.6471,0.49876,46.9698L11.8268,58.493C12.144,58.8156,12.5745,59,13.0276,
          59C13.4807,59,13.9112,58.8156,14.2284,58.493L29.0115,43.4437L43.8286,58.493C44.1458,58.8156,44.5763,59,45.0294,59C45.4825,59,45.913,
          58.8156,46.2301,58.493L57.5016,46.9698C58.1586,46.2899,58.1586,45.2067,57.5016,44.5269L42.7071,29.4775ZM34.8908,14.4282L38.4931,
          18.0926C38.8216,18.4268,39.2634,18.5996,39.6939,18.5996C40.1243,18.5996,40.5661,18.4268,40.8946,18.0926C41.563,17.4128,41.563,16.318,
          40.8946,15.6497L37.2923,11.9853L40.5548,8.66664L44.1571,12.331C44.4856,12.6652,44.9274,12.838,45.3579,12.838C45.7884,12.838,46.2301,
          12.6652,46.5587,12.331C47.227,11.6511,47.227,10.5564,46.5587,9.8881L42.9563,6.22372L44.9727,4.17259L53.8992,13.2068L40.3056,27.0346L31.4017,
          17.9774L34.8908,14.4282ZM11.9174,5.30186L5.21123,12.1236L3.79523,3.86146L11.9174,5.30186ZM13.0276,54.8286L4.10108,45.7483L6.11748,
          43.6972L9.7198,47.3616C10.0483,47.6957,10.4901,47.8686,10.9206,47.8686C11.351,47.8686,11.7928,47.6957,12.1214,47.3616C12.7897,46.6817,
          12.7897,45.587,12.1214,44.9186L8.51903,41.2543L11.7815,37.9356L15.3838,41.6C15.7123,41.9341,16.1541,42.107,16.5846,42.107C17.0151,42.107,
          17.4569,41.9341,17.7854,41.6C18.4537,40.9201,18.4537,39.8254,17.7854,39.157L14.1831,35.4927L17.6834,31.932L26.5986,40.9892L13.0276,
          54.8286ZM45.018,54.8286L6.50263,15.6958L9.76511,12.3771L48.2465,51.5214L45.018,54.8286ZM50.6481,49.0785L12.1667,9.93419L15.4291,
          6.61551L53.8992,45.7483L50.6481,49.0785Z" stroke="url(#linear_gradient_1)"/>
      </g>
    </SvgIcon>
  ),
  Hand: () => (
    <SvgIcon viewBox='-5 -5 50 50'>
      <defs>
        <SvgIconGradient />
      </defs>
      <g>
      <path className='empty-fill' d="M3.40187,23.4652C3.40962,23.458,3.41663,23.4515,3.42295,23.4457L3.48728,23.3902C3.66843,23.2337,3.88514,23.15,4.15032,23.15C4.32722,
        23.15,4.49489,23.1965,4.66468,23.2908L4.77991,23.3548L4.78702,23.3566C4.83719,23.3845,4.9043,23.4221,4.98721,23.4688C5.18621,23.581,5.46814,
        23.741,5.80611,23.9334C6.48167,24.3179,7.37671,24.8295,8.27031,25.3408C9.16376,25.8521,10.0552,26.3629,10.7233,26.7459L11.5302,27.2086L11.7561,
        27.3382L11.8156,27.3723L11.8308,27.3811L11.8347,27.3833L11.8357,27.3838L11.8359,27.384L12.3337,26.5167L11.836,27.384L13.3337,
        28.2434V26.5167V6.66667C13.3337,5.83562,14.0026,5.16667,14.8337,5.16667C15.6647,5.16667,16.3337,5.83562,16.3337,
        6.66667V18.3333V19.3333H17.3337H19.0003H20.0003V18.3333V2.5C20.0003,1.66895,20.6693,1,21.5003,1C22.3314,1,23.0003,1.66895,23.0003,
        2.5V18.3333V19.3333H24.0003H25.667H26.667V18.3333V4.16667C26.667,3.33562,27.3359,2.66667,28.167,2.66667C28.998,2.66667,29.667,3.33562,29.667,
        4.16667V18.3333V19.3333H30.667H32.3337H33.3337V18.3333V9.16667C33.3337,8.33562,34.0026,7.66667,34.8337,7.66667C35.6647,7.66667,36.3337,8.33562,
        36.3337,9.16667V33.3333C36.3337,36.4477,33.7814,39,30.667,39H18.5003C16.9686,39,15.525,38.3911,14.4625,37.3147C14.4624,37.3145,14.4622,37.3144,
        14.4621,37.3143L2.08482,24.732C2.19316,24.6268,2.30696,24.5163,2.42112,24.4057C2.68734,24.1478,2.95396,23.8905,3.15747,23.6963C3.25967,23.5987,
        3.34321,23.5197,3.40187,23.4652Z" stroke="url(#linear_gradient_1)" strokeWidth="2"/>
      </g>
    </SvgIcon>
  ),
  Eye: () => (
    <SvgIcon viewBox='0 -5 40 40'>
      <defs>
        <SvgIconGradient />
      </defs>
      <g>
        <path className="empty-fill" d="M20,26.2727C11.4749,26.2727,4.17005,21.0562,1.07896,13.6364C4.17005,6.21651,11.4749,1,20,1C28.5251,1,35.8299,6.21652,38.921,
          13.6364C35.8299,21.0562,28.5251,26.2727,20,26.2727ZM9.90909,13.6364C9.90909,19.2068,14.4295,23.7273,20,23.7273C25.5705,23.7273,30.0909,
          19.2068,30.0909,13.6364C30.0909,8.0659,25.5705,3.54545,20,3.54545C14.4295,3.54545,9.90909,8.0659,9.90909,13.6364ZM15.5455,13.6364C15.5455,
          11.1705,17.5341,9.18182,20,9.18182C22.4659,9.18182,24.4545,11.1705,24.4545,13.6364C24.4545,16.1023,22.4659,18.0909,20,18.0909C17.5341,18.0909,
          15.5455,16.1023,15.5455,13.6364Z" stroke="url(#linear_gradient_1)" strokeWidth="2"/>
      </g>
    </SvgIcon>
  ),
  Gamepad: () => (
    <SvgIcon viewBox='0 -10 55 55'>
      <defs>
        <SvgIconGradient />
      </defs>
      <g>
      <path className='gradient-fill' d="M53.036,24.2053C52.9038,21.152,52.3751,17.767,51.5819,14.581C50.7888,11.5278,49.7974,8.6737,48.6077,6.48335C43.9811,
        -1.68067,38.958,-0.419563,32.9434,1.17342C31.0267,1.63804,28.9777,2.16903,26.8627,2.36815H26.2018C24.0868,2.16903,22.0379,1.63804,20.1211,1.17342C14.1066,
        -0.353189,9.08341,-1.68067,4.45683,6.54973C3.26713,8.74007,2.20963,11.5942,1.48259,14.6474C0.689463,17.8333,0.16071,21.2184,0.0285217,24.2716C-0.103666,
        27.6567,0.226804,30.1126,0.953839,31.8383C1.68087,33.4313,2.73838,34.4269,4.12636,34.8251C5.38214,35.157,6.83621,35.0243,8.35638,34.3605C10.934,33.2985,
        13.9083,31.0418,16.8164,28.3205C18.8653,26.3292,22.6988,25.3336,26.5323,25.3336C30.3657,25.3336,34.1992,26.3292,36.2481,28.3205C39.1562,31.0418,42.1305,
        33.2985,44.7081,34.3605C46.2283,34.9579,47.6824,35.157,48.9382,34.8251C50.26,34.4269,51.3836,33.4976,52.1107,31.7719C52.8377,30.1126,53.1682,27.6567,
        53.036,24.2053ZM49.8635,30.8427C49.4669,31.7719,48.9382,32.3029,48.2772,32.502C47.5502,32.7011,46.6249,32.5684,45.5674,32.1702C43.2541,31.2409,40.5442,
        29.1833,37.9004,26.6611C35.3889,24.1389,30.9606,22.8778,26.5323,22.8778C22.104,22.8778,17.6756,24.1389,15.098,26.5284C12.3881,29.0506,9.67826,31.1745,
        7.43106,32.0374C6.37356,32.4357,5.44824,32.6348,4.7212,32.3693C4.06026,32.1702,3.53151,31.6392,3.13494,30.7099C2.60619,29.4488,2.34182,27.3912,2.474,
        24.338C2.60619,21.4839,3.06885,18.2316,3.86198,15.1784C4.58901,12.3243,5.51433,9.66931,6.63793,7.67809C10.2731,1.10704,14.5031,2.16903,19.5263,
        3.49651C21.5752,4.02751,23.6902,4.5585,26.0035,4.75762C26.0696,4.75762,26.0696,4.75762,26.1357,4.75762H26.8627C26.9288,4.75762,26.9288,4.75762,26.9949,
        4.75762C29.3743,4.5585,31.4893,4.02751,33.5382,3.49651C38.5614,2.2354,42.7914,1.10704,46.4266,7.67809C47.5502,9.66931,48.4755,12.2579,49.2025,
        15.1784C49.9296,18.2316,50.4583,21.4175,50.5905,24.338C50.7227,27.3912,50.4583,29.4488,49.8635,30.8427Z" />
      <path className='gradient-fill' d="M20.4513,11.262C19.7904,10.6646,18.9972,10.2,18.0719,10.1336C18.0058,9.27073,17.6092,8.40786,17.0144,7.8105L16.9483,
        7.74412C16.2213,7.01401,15.296,6.61576,14.2384,6.61576C13.1809,6.61576,12.1895,7.08038,11.5286,7.74412C10.9337,8.34149,10.4711,9.20435,10.405,
        10.1336C9.47967,10.2,8.68654,10.5982,8.0256,11.1956L7.95951,11.262C7.23247,11.9921,6.83591,12.9213,6.83591,13.9833C6.83591,15.0453,7.29857,16.0409,
        7.95951,16.7046C8.62045,17.3684,9.41358,17.7666,10.405,17.833C10.4711,18.7622,10.8676,19.6251,11.5286,20.2225C12.2556,20.9526,13.1809,21.3508,14.2384,
        21.3508C15.296,21.3508,16.2874,20.8862,16.9483,20.2225C17.5432,19.5587,18.0058,18.7622,18.0719,17.833C18.9972,17.7666,19.8564,17.3684,20.4513,
        16.7046C21.1783,15.9745,21.5749,15.0453,21.5749,13.9833C21.5749,12.9213,21.1122,11.9257,20.4513,11.262ZM18.7989,14.9789C18.5346,15.2444,18.2041,
        15.3772,17.8075,15.3772H16.8822C16.2213,15.3772,15.6264,15.9081,15.6264,16.6383V17.5011C15.6264,17.8994,15.4942,18.2312,15.2299,18.4967C14.9655,
        18.7622,14.635,18.895,14.2384,18.895C13.8419,18.895,13.5114,18.7622,13.247,18.4967C12.9827,18.2312,12.8505,17.8994,12.8505,17.5011V16.5719C12.8505,
        15.9081,12.3217,15.3108,11.5947,15.3108H10.6694C10.2728,15.3108,9.94233,15.178,9.67795,14.9125C9.47967,14.7134,9.28139,14.3815,9.28139,13.9833C9.28139,
        13.5851,9.41358,13.2532,9.67795,12.9877C9.67795,12.9877,9.67795,12.9877,9.74405,12.9213C10.0084,12.7222,10.3389,12.5894,10.6694,12.5894H11.5947C12.2556,
        12.5894,12.8505,12.0584,12.8505,11.3283V10.3991C12.8505,10.0008,12.9827,9.66897,13.247,9.40348C13.5114,9.13798,13.8419,9.00523,14.2384,9.00523C14.635,
        9.00523,14.9655,9.13798,15.2299,9.40348C15.2299,9.40348,15.2299,9.40348,15.296,9.46985C15.4942,9.73535,15.6264,10.0672,15.6264,10.3991V11.3283C15.6264,
        11.9921,16.1552,12.5894,16.8822,12.5894H17.8075C18.2041,12.5894,18.5346,12.7222,18.7989,12.9877C19.0633,13.2532,19.1955,13.5851,19.1955,13.9833C19.1955,
        14.3815,19.0633,14.7134,18.7989,14.9789Z"/>
      <path className='gradient-fill' d="M38.0323,11.5938C39.3099,11.5938,40.3456,10.5537,40.3456,9.27072C40.3456,7.98771,39.3099,6.94762,38.0323,6.94762C36.7547,
        6.94762,35.719,7.98771,35.719,9.27072C35.719,10.5537,36.7547,11.5938,38.0323,11.5938Z"/>
      <path className='gradient-fill' d="M38.0323,21.0189C39.3099,21.0189,40.3456,19.9789,40.3456,18.6959C40.3456,17.4128,39.3099,16.3728,38.0323,16.3728C36.7547,
        16.3728,35.719,17.4128,35.719,18.6959C35.719,19.9789,36.7547,21.0189,38.0323,21.0189Z" />
      <path className='gradient-fill' d="M33.3397,16.3064C34.6173,16.3064,35.653,15.2663,35.653,13.9833C35.653,12.7003,34.6173,11.6602,33.3397,11.6602C32.0621,
        11.6602,31.0264,12.7003,31.0264,13.9833C31.0264,15.2663,32.0621,16.3064,33.3397,16.3064Z" />
      <path className='gradient-fill' d="M42.7249,16.3064C44.0025,16.3064,45.0382,15.2663,45.0382,13.9833C45.0382,12.7003,44.0025,11.6602,42.7249,11.6602C41.4473,
        11.6602,40.4116,12.7003,40.4116,13.9833C40.4116,15.2663,41.4473,16.3064,42.7249,16.3064Z" />
      </g>
    </SvgIcon>
  ),
  Conversions: () => (
    <SvgIcon viewBox='-7 0 35 35'>
      <defs>
        <SvgIconGradient />
      </defs>
      <g>
        <path className='gradient-fill' d="M12.5,4.63636V0L6.25,6.18182L12.5,12.3636V7.72727C17.6719,7.72727,21.875,11.8845,21.875,17C21.875,18.5609,21.4844,20.0445,
        20.7812,21.3273L23.0625,23.5836C24.2812,21.6827,25,19.4264,25,17C25,10.1691,19.4062,4.63636,12.5,4.63636ZM12.5,26.2727C7.32812,26.2727,
        3.125,22.1155,3.125,17C3.125,15.4391,3.51562,13.9555,4.21875,12.6727L1.9375,10.4164C0.71875,12.3173,0,14.5736,0,17C0,23.8309,5.59375,
        29.3636,12.5,29.3636V34L18.75,27.8182L12.5,21.6364V26.2727Z" />
      </g>
    </SvgIcon>
  )
};
