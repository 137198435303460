import { useTemporaryState, useWillUnMountEffect, Image, useInterval } from '@rocketmakers/armstrong-edge';
import * as React from 'react';
import { Assets } from '../content/assets';
import { useTimeout } from '../hooks/useTimeout';
import { MathsUtils } from '../utils/maths';
import './copySectionAnimation.scss';
import { FeatureGraphicAnalyticsCard, GraphicsCardVariant, GraphicsCardVariantList } from './featureGraphicAnalyticsCard';
import { FeatureGraphicAnalyticsBubble } from './featureGraphicAnalyticsImage';


interface ICopySectionAnimationProps {
  animation?: string;
}

interface IAllAnalyticsAnimationNumbers {
  actual: number;
  percentage: number;
}
interface IAllAnalyticsAnimationNumberState{
  delivered: IAllAnalyticsAnimationNumbers;
  interaction: IAllAnalyticsAnimationNumbers;
  users: IAllAnalyticsAnimationNumbers;
  gaze: IAllAnalyticsAnimationNumbers;
  conversion: IAllAnalyticsAnimationNumbers;
}

export const AllAnalyticsAnimation: React.FC = () => {
  const [changing, setChanging] = useTemporaryState(false, 300);


  const timeout = React.useRef<NodeJS.Timeout>();
  useWillUnMountEffect(() => timeout.current && window.clearTimeout(timeout.current));
  React.useEffect(() => {
    timeout.current = setTimeout(() => {
    setChanging(true);
    }, 10000);

    return () => {
    if (timeout.current) {
        window.clearTimeout(timeout.current);
    }
    };
  }, [changing]);

  const numberState = React.useMemo<IAllAnalyticsAnimationNumberState>(() => {
    
    const actualSeed = MathsUtils.randomBetween(700, 14000);

    return ({
      delivered: {
        actual: actualSeed,
        percentage: MathsUtils.randomBetween(-30, 70)
      },
      interaction: {
        actual: Math.floor(actualSeed * 3),
        percentage: MathsUtils.randomBetween(-30, 70)
      },
      users: {
        actual: Math.floor(actualSeed / 4),
        percentage: MathsUtils.randomBetween(-10, 70)
      },
      gaze: {
        actual: Math.floor(actualSeed * 10),
        percentage: MathsUtils.randomBetween(-30, 70)
      },
      conversion: {
        actual: Math.floor(actualSeed / 11),
        percentage:  MathsUtils.randomBetween(-30, 70)
      }
    })
  }, [changing]);


  React.useEffect(() => {

  }, [changing]);



  return (
  <div className='analytics-container'>
    {!changing && <>
      <FeatureGraphicAnalyticsCard 
        actualEnd={numberState.delivered.actual} 
        percentageChangeEnd={numberState.delivered.percentage} 
        variant='delivered' 
        className='analytics-container-item delivered-item'
        />
      <FeatureGraphicAnalyticsCard 
        actualEnd={numberState.interaction.actual}
        percentageChangeEnd={numberState.interaction.percentage} 
        variant='interaction' 
        className='analytics-container-item interaction-item'
        />
      <FeatureGraphicAnalyticsCard 
        actualEnd={numberState.users.actual} 
        percentageChangeEnd={numberState.users.percentage} 
        variant='users' 
        className='analytics-container-item users-item'
        />
      <FeatureGraphicAnalyticsCard 
        actualEnd={numberState.gaze.actual} 
        percentageChangeEnd={numberState.gaze.percentage} 
        variant='gaze' 
        className='analytics-container-item gaze-item'
        />
      <FeatureGraphicAnalyticsCard 
        actualEnd={numberState.conversion.actual} 
        percentageChangeEnd={numberState.conversion.percentage} 
        variant='conversion' 
        className='analytics-container-item conversion-item'
      />
    </>}
  </div>)
}

export const OneAnalyticsAnimation: React.FC = () => {
  const {changing} = useTimeout(6000);
  const [actualEnd, setActualEnd] = React.useState(MathsUtils.randomBetween(50, 800));
  const [variant, setVariant] = React.useState<GraphicsCardVariant>(MathsUtils.randomFromArray(GraphicsCardVariantList));
  const [percentageEnd, setPercentageEnd] = React.useState(MathsUtils.randomBetween(4, 88, 1));
  const [bubbleSize, setBubbleSize] = React.useState(0.5);

  const updateNumbers = React.useCallback(() => {
    const seed = MathsUtils.randomBetween(1, 2, 0.01);
    setActualEnd(actualEnd * seed);
    setPercentageEnd((seed - 1) * 100);
    setBubbleSize(bubbleSize + 0.5);
  }, [changing, actualEnd, percentageEnd, bubbleSize]);

  const {clear} = useInterval(updateNumbers, 3500, { setOnMount: true });

  React.useEffect(() => {
    if(changing) {
      clear();
      let newVariant = variant;
      
      setActualEnd(MathsUtils.randomBetween(50, 600));
      while (!newVariant || newVariant == variant){
        newVariant = MathsUtils.randomFromArray(GraphicsCardVariantList);
      }
      setVariant(newVariant);
      setPercentageEnd(100);
      setBubbleSize(0.5);
    }
  }, [changing]);

  return (
    <div className='analytics-one-container'>
      <Image
        src={Assets.analyticsImage}
        alt=""
        />
        {!changing &&
        <>
          <FeatureGraphicAnalyticsBubble overrideSize={bubbleSize}/>
          <FeatureGraphicAnalyticsCard 
            actualEnd={actualEnd} 
            percentageChangeEnd={percentageEnd}
            className="analytics-one-card" 
            variant={variant} 
            fixedPosition={{left: 60, bottom: 5}}
            countUpDuration={1}
            />
        </>}
    </div>
  );
}

export const CopySectionAnimation: React.FunctionComponent<ICopySectionAnimationProps> = ({
  animation
}) => {
    const animationComponent = React.useMemo(() => {
        switch(animation){
            case 'analytics_all':
                return <AllAnalyticsAnimation />
            case 'analytics_one':
              return <OneAnalyticsAnimation />
            case 'worldwide':
            case 'updates':
            case 'none':
            default:
                return undefined;

        }
    }, [animation]);
  return (
    <>
      {animationComponent}
    </>
  )
};
