import { useDelayedDependentSwitch } from '@rocketmakers/armstrong-edge';
import * as React from 'react';

import { useDisableScroll } from '../hooks/useDisableScroll';
import { Nav } from './nav';

import './hamburger.scss';

export interface IHamburgerProps {
  isOpen: boolean;
}

export const Hamburger: React.FC<IHamburgerProps> = ({ isOpen }) => {
  const [open, closing] = useDelayedDependentSwitch(isOpen, 600);

  useDisableScroll(!!open);

  if (!open && !closing) {
    return null;
  }

  return (
    <div className="bm-hamburger-wrapper" data-is-closing={closing}>
      <div
        className="bm-hamburger"
        data-is-open={isOpen}
        aria-modal="true"
        role="navigation"
        aria-label="Menu"
        id="hamburger-menu"
      >
        <div className="bm-hamburger-header-bg" />
        <div className="bm-hamburger-inner">
          <Nav />
        </div>
      </div>
    </div>
  );
};
