import { PrismicRichTextProps } from '@prismicio/react';
import { Button, Expandable } from '@rocketmakers/armstrong-edge';
import * as React from 'react';

import { RichText } from './richText';

import { SvgIcons } from './svgIcon';

import './faqs.scss';

interface IFaqProps {
  question?: string;
  answer?: PrismicRichTextProps['field'];
}

export const Faq: React.FunctionComponent<IFaqProps> = ({ question, answer }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <div className="faq" key={question} data-open={open}>
      <Button className="faq-question" rightIcon={<SvgIcons.Down />} onClick={() => setOpen(!open)}>
        <p>{question}</p>
      </Button>
      <Expandable isOpen={open} direction="vertical">
        <div className="faq-answer">
          <RichText field={answer} />
        </div>
      </Expandable>
    </div>
  );
};

interface IFaqsProps {
  faqs: IFaqProps[];
}
export const Faqs: React.FunctionComponent<IFaqsProps> = ({ faqs }) => {
  return (
    <div className="faqs">
      {faqs.map(faq => (
        <Faq {...faq} key={faq.question || ''} />
      ))}
    </div>
  );
};
